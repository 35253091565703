import styled from 'styled-components';
import { GRAY_200 } from '../../Config/colors';

export const LineDiv = styled.div`
  background: ${GRAY_200};
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`;
