export const CREATE_PROJECT = 'CREATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const EDIT_PROJECT_DETAILS = 'EDIT_PROJECT_DETAILS';
export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
export const PROJECTS_SCREEN_VISABILITY = 'PROJECTS_SCREEN_VISABILITY';
export const SHOOT_SETUP_SCREEN = 'SHOOT_SETUP_SCREEN';
export const VIDEO_AUDIO_ROUTING = 'VIDEO_AUDIO_ROUTING';
export const CAMERA_ASSIGN = 'CAMERA_ASSIGN';
export const CAMERA_CONTROL = 'CAMERA_CONTROL';
export const COMMS = 'COMMS';
export const EC2_CREATE = 'EC2_CREATE';
export const EC2_ACCESS = 'EC2_ACCESS';
export const MULTIVIEW = 'MULTIVIEW';
export const PROGRAM_FEED_FILE = 'PROGRAM_FEED_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FILE_MOVEMENT = 'FILE_MOVEMENT';
export const USAGE_AND_BILLING = 'USAGE_AND_BILLING';
export const EDIT_SETUP_SCREEN = 'EDIT_SETUP_SCREEN';
export const MEDIA_ASSET_MGMNT = 'MEDIA_ASSET_MGMNT';
export const QR_SCAN = 'QR_SCAN';
export const NO_PERMISSION = 'NO_PERMISSION';
export const ADD_COLLABORATORS = 'ADD_COLLABORATORS';
export const PROJECT_DATES = 'PROJECT_DATES';
export const ONBOARD_CAMERA = 'ONBOARD_CAMERA';
export const ADD_LINKS = 'ADD_LINKS';
export const EDIT_LINKS = 'EDIT_LINKS';
export const DELETE_LINKS = 'DELETE_LINKS';
export const VIEW_LINKS = 'VIEW_LINKS';
export const ADD_FILES = 'ADD_FILES';
export const EDIT_FILES = 'EDIT_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const VIEW_FILES = 'VIEW_FILES';
export const ADD_COMMS = 'ADD_COMMS';
export const EDIT_COMMS = 'EDIT_COMMS';
export const DELETE_COMMS = 'DELETE_COMMS';
export const VIEW_COMMS = 'VIEW_COMMS';
// "ADD_LINKS", "EDIT_LINKS", "DELETE_LINKS", "VIEW_LINKS",
// "ADD_FILES", "EDIT_FILES", "DELETE_FILES", "VIEW_FILES",

export const Rec_Format_Options1 = [
  { value: '1080', label: '1080' },
  { value: '4k', label: '4k' },
];
export const Int_Rec_Format_Options2 = [
  { value: '23.98', label: '23.98' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '29.97', label: '29.97' },
  { value: '50', label: '50' },
  { value: '59.94', label: '59.94' },
  // { value: '25', label: '25' },
  // { value: '30', label: '30' },
  // { value: '50', label: '50' },
  // { value: '60', label: '60' },
];
export const Rec_Format_Options2 = [
  { value: 'p24', label: 'p24' },
  { value: 'p25', label: 'p25' },
  { value: 'p30', label: 'p30' },
  { value: 'p50', label: 'p50' },
  { value: 'p60', label: 'p60' },
  // { value: '25', label: '25' },
  // { value: '30', label: '30' },
  // { value: '50', label: '50' },
  // { value: '60', label: '60' },
];

export const int_Rec_Format_Options3 = [
  { value: 'h.264', label: 'h.264' },
  { value: 'h.265', label: 'h.265' },
  { value: 'ProRes', label: 'ProRes' },
  { value: 'ProResHQ', label: 'ProResHQ' },
];

export const ext_Rec_Format_Options3 = [
  { value: 'h.264', label: 'h.264' },
  { value: 'h.265', label: 'h.265' },
];

export const regionOptions = [
  { value: 'us-east-2', label: 'US East (Ohio)' },
  { value: 'us-east-1', label: 'US East (N. Virginia)' },
  { value: 'us-west-1', label: 'US West (N. CA)' },
  { value: 'us-west-2', label: 'US West (Oregon)' },
  { value: 'ca-central-1', label: 'Canada (Central)' },
  { value: 'eu-central-1', label: 'Europe (Frankfurt)' },
  { value: 'eu-west-1', label: 'Europe (Ireland)' },
  { value: 'eu-west-2', label: 'Europe (London)' },
  { value: 'eu-west-3', label: 'Europe (Paris)' },
  { value: 'eu-south-1', label: 'Europe (Milan)' },
  { value: 'eu-north-1', label: 'Europe (Stockholm)' },
];

export const typeOptions = [
  { value: 'VIMIX_MAIN', label: 'Main Switcher' },
  { value: 'VIMIX_REPLY', label: 'Replay' },
  { value: 'SRT_GATEWAY', label: 'Gateway' },
];

export const sizeOptions = [
  { value: 'SMALL', label: 'Small' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'LARGE', label: 'Large' },
  { value: 'XL', label: 'X-Large' },
];

export const namesRegEx = {
  value: /^([A-Za-z'’.\s-]+[ ·•]?)+[A-Za-z'’.\s-]+$|^[A-Za-z'’.\s-]+$/,
  message:
    'can only contain letters, spaces, apostrophes, hyphens, periods, and special characters like · or •.',
};
