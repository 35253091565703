import { CAMERA_STATUS_COLORS } from 'Config/app';

export const determineCameraStatusColor = (
  assets,
  cameraAirId,
  statusObj,
  userHasAccess,
) => {
  const timeNow = new Date();
  if (assets) {
    const thisCamera = assets?.allGears?.camera?.find(
      asset => asset.camera.air_id === cameraAirId,
    );
    // console.log('... thisCamera', thisCamera, 'cameraAirId', cameraAirId);
    // check if camera is expired
    if (thisCamera) {
      const cameraExpired = new Date(thisCamera.end_date) < timeNow;
      if (cameraExpired) return CAMERA_STATUS_COLORS.BLACK;
    }
  }

  // determine status by user access
  if (!userHasAccess) return CAMERA_STATUS_COLORS.RED;
  else {
    // console.log('... statusObj', statusObj);
    // check if camera has been inactive in the last 3 minutes
    const cameraInactivityThresholdMinutes = 3;
    const cameraInactiveRecently =
      new Date(statusObj?.last_activity) <
      timeNow - cameraInactivityThresholdMinutes * 60 * 1000;
    if (cameraInactiveRecently) return CAMERA_STATUS_COLORS.GREY;

    // show gold / green based on status
    if (
      statusObj?.status === 1 ||
      (statusObj?.viewers > 0 && statusObj?.controller === 0)
    )
      return CAMERA_STATUS_COLORS.GOLD;
    if (statusObj?.status === 0 || statusObj?.controller === 0)
      return CAMERA_STATUS_COLORS.GREEN;
  }
  return CAMERA_STATUS_COLORS.GREEN;
};
