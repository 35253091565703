import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  AddCrewContentContainer,
  AddGearFormContainer,
  NavFooter,
  DoneButton,
} from './styles';
import { FormErrorText } from '../CommonStyles';
import { OutlinedButton } from '../IconButton/styles';
import { apiEditProjectLinksRequest } from 'Redux/actions/project';

const EditExternalLinks = ({ auth, onRightNavClose, projectId, linkData }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: linkData?.title || '',
      url: linkData?.url || 'https://',
    },
    mode: 'onSubmit',
  });

  const onEditExternalLinksFormSubmit = () => {
    handleSubmit(data => {
      const result = {
        project_id: projectId,
        title: data?.title,
        url: data?.url,
        id: linkData?.id,
      };
      dispatch(apiEditProjectLinksRequest(result, auth.token));
      onRightNavClose();
    })();
  };

  return (
    <AddCrewContentContainer>
      <AddGearFormContainer>
        <form>
          <div className="mb-3">
            <label htmlFor="link-title" className="form-label">
              Title
            </label>

            <input
              {...register('title', {
                required: true,
              })}
              type="text"
              className="form-control"
              placeholder="Add Title"
            />

            {errors?.title ? (
              <FormErrorText className="form-text">
                This field is required
              </FormErrorText>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="link-url" className="form-label">
              Link
            </label>

            <input
              {...register('url', {
                required: {
                  value: true,
                  message: 'This field is required',
                },
                pattern: {
                  value:
                    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                  message: 'This URL is not valid',
                },
              })}
              type="text"
              className="form-control"
              placeholder="Add URL"
            />

            {errors?.url ? (
              <FormErrorText className="form-text">
                {errors?.url?.message}
              </FormErrorText>
            ) : null}
          </div>
          <div style={{ border: '1px solid #E4E7EC' }} className="my-3"></div>
        </form>
      </AddGearFormContainer>
      <NavFooter>
        <OutlinedButton className="btn btn-sm" onClick={onRightNavClose}>
          Cancel
        </OutlinedButton>
        <DoneButton
          className="btn btn-primary btn-sm"
          onClick={onEditExternalLinksFormSubmit}
        >
          Apply
        </DoneButton>
      </NavFooter>
    </AddCrewContentContainer>
  );
};

export default EditExternalLinks;
