import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import PageRouter from './PageRouter';
import { apiUpdateUserOnlineStatusRequest } from 'Redux/actions/onlineStatus';
import { set } from 'date-fns';

function App() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  let intervalId;

  const sendOnlineStatus = () => {
    if (auth.token) {
      dispatch(apiUpdateUserOnlineStatusRequest(true, auth.token));
    }
  };

  const setOffline = e => {
      if (auth.token) {
        dispatch(apiUpdateUserOnlineStatusRequest(false, auth.token));
      }
    };

    useEffect(() => {
      let intervalId = setInterval(sendOnlineStatus, 30000);
    
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
          setOffline(); // Set offline status immediately
          clearInterval(intervalId);
        } else {
          sendOnlineStatus();
          intervalId = setInterval(sendOnlineStatus, 30000);
        }
      };
    
      window.addEventListener('visibilitychange', handleVisibilityChange);
    
      return () => {
        setOffline();
        clearInterval(intervalId);
        window.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [dispatch, auth.token]); // Include auth.token in dependencies to ensure effect updates when token changes
    
    useEffect(() => {
      sendOnlineStatus(); // Initial online status when component mounts
    }, []);

  // useEffect(() => {
    // const setOffline = e => {
    //   if (auth.token) {
    //     dispatch(apiUpdateUserOnlineStatusRequest(false, auth.token));
    //   }
    // };
    // const setOnline = e => {
    //   if (auth.token) {
    //     dispatch(apiUpdateUserOnlineStatusRequest(true, auth.token));
    //   }
    // };

    // setTimeout(setOnline, 5000);
    // window.addEventListener('blur', setOffline);
    // window.addEventListener('focus', setOnline);
    // window.addEventListener('pagehide',setOffline);

    // return () => {
    //   setOffline();
    //   window.removeEventListener('blur', setOffline);
    //   window.removeEventListener('focus', setOnline);
    //   window.removeEventListener('pagehide',setOffline);
    // };
  // }, [dispatch]);
  return <PageRouter />;
}

export default App;
