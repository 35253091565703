import React, { useState, useEffect, useRef } from 'react';
import tick from '../../../Assets/images/tick-square.png';
import CheckboxBase from '../../../Assets/images/checkboxBase.png';
import InsIcon from '../../../Assets/images/GearInsIcon.png';
import CameraIcon from '../../../Assets/images/GearCamIcon.png';
import moment from 'moment';
import {
  Collabolators,
  CollabolatorsAvatar,
  EachCollabolatorProject,
  EachCollabolators,
  EachCollabolatorsP,
  EachCollabolatorsP1,
  EachCollabolatorsP2,
  EachCollabolatorsP2Div,
  EachCollabolatorsP3,
  EachCollabolatorsP3P,
  EachCollabolatorsP4,
  EachCollabolatorsP5,
  EachCollabolatorsP5P,
  EachCollabolatorsP6P,
  MainSelectImg,
} from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { allCollabolators } from 'Redux/actions/team';
import GearList from './GearList';
import { typeOptions } from 'Config/permissionConstant';
import SearchFooter from './SearchFooter';

const GearsList = props => {
  let {
    onChange,
    editGear,
    gears,
    name_filter,
    device_filter,
    project_filter,
    indexOfLastPost,
    indexOfFirstPost,
    filterAssets,
    state,
    postsPerPage,
    setCurrentPage,
    currentPage,
    prePage,
    nextPg,
  } = props;
  let filterByDevice = device_filter ? parseInt(device_filter) : 0;

  const [gearsList, setGearList] = useState([]);
  useEffect(() => {
    if (gears) {
      // const currentPosts = gears?.slice(indexOfFirstPost, indexOfLastPost);
      /*
      let inst = [];
      let cam = [];
  
      setGearList(
        gears.map(f => {
           f.instance_type ? inst.push(f) : cam.push(f);
        }),
      );
      cam.forEach(fe => (fe['instance_type'] = 'AIR One™'));
      let newArr2 = inst.concat(cam);

      // newArr2 = newArr2?.slice(indexOfFirstPost, indexOfLastPost);
      setGearList(newArr2);
      */
    }
  }, [gears, indexOfFirstPost, indexOfLastPost]);

  useEffect(() => {
    // if (gears.length > 0) {
      if (true) {
      let newArr = gears
        .filter(
          f =>
            f?.nick_name
              .toLowerCase()
              .includes(name_filter.toLowerCase().trim()) ||
            f.camera?.nick_name
              .toLowerCase()
              .includes(name_filter.toLowerCase().trim()),
        )
        .filter(f =>
          filterByDevice === 1
            ? 'camera' in f
            : filterByDevice === 2
            ? f?.instance_type === 'VIMIX_MAIN'
            : filterByDevice === 3
            ? f?.instance_type === 'VIMIX_REPLY'
            : filterByDevice === 4
            ? f?.instance_type === 'SRT_GATEWAY'
            : f,
        )
        .filter(f =>
          state.category_filter
            ? state.category_filter?.toLowerCase() === 'available'
              ? f?.is_available
              : state.category_filter?.toLowerCase() === 'engaged'
              ? !f?.is_available
              : f
            : f,
        )
        ?.map(f => {
          if (!f.instance_type) {
            f['instance_type'] = 'AIR One™';
          }
          return f;
        });

      if (
        filterAssets.heading === 'name' &&
        filterAssets.arrowDirection === 'up'
      ) {
        newArr = newArr.sort((a, b) =>
          (a?.camera?.nick_name || a?.nick_name)?.toLowerCase() >
          (b?.camera?.nick_name || b?.nick_name)?.toLowerCase()
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'name' &&
        filterAssets.arrowDirection === 'down'
      ) {
        newArr = newArr.sort((a, b) =>
          (a?.camera?.nick_name || a?.nick_name)?.toLowerCase() <
          (b?.camera?.nick_name || b?.nick_name)?.toLowerCase()
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'type' &&
        filterAssets.arrowDirection === 'up'
      ) {
        newArr = newArr.sort((a, b) =>
          a.instance_type?.toLowerCase() > b.instance_type?.toLowerCase()
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'type' &&
        filterAssets.arrowDirection === 'down'
      ) {
        newArr = newArr.sort((a, b) =>
          a.instance_type?.toLowerCase() < b.instance_type?.toLowerCase()
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'price' &&
        filterAssets.arrowDirection === 'up'
      ) {
        newArr = newArr.sort((a, b) =>
          (a?.package_info?.price || 0) > (b?.package_info?.price || 0)
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'price' &&
        filterAssets.arrowDirection === 'down'
      ) {
        newArr = newArr.sort((a, b) =>
          (a?.package_info?.price || 0) < (b?.package_info?.price || 0)
            ? 1
            : -1,
        );
      } else if (
        filterAssets.heading === 'exp_date' &&
        filterAssets.arrowDirection === 'up'
      ) {
        const endDate = endDateStr =>
          endDateStr ? moment(endDateStr)?.format('x') : 0;
        newArr = newArr.sort((a, b) =>
          endDate(a.end_date) > endDate(b?.end_date) ? 1 : -1,
        );
      } else if (
        filterAssets.heading === 'exp_date' &&
        filterAssets.arrowDirection === 'down'
      ) {
        const endDate = endDateStr =>
          endDateStr ? moment(endDateStr)?.format('x') : 0;
        newArr = newArr.sort((a, b) =>
          endDate(a.end_date) < endDate(b.end_date) ? 1 : -1,
        );
      }
      // newArr = newArr?.slice(indexOfFirstPost, indexOfLastPost);

      setGearList(newArr);
    }
  }, [
    filterAssets,
    name_filter,
    device_filter,
    state,
    gears,
    indexOfFirstPost,
    indexOfLastPost,
  ]);
  return (
    <>
      <Collabolators>
        {gearsList
          ?.slice(indexOfFirstPost, indexOfLastPost)
          ?.map((user, index) => {
            return (
              <EachCollabolators
                key={index}
                is_deactivated={user.is_deactivated}
              >
                <EachCollabolatorsP1>
                  {/* <MainSelectImg
                  src={user?.isSelected ? tick : CheckboxBase}
                  // onClick={() => (user.isSelected = !user.isSelected)}
                  onClick={() => onChange(user, index)}
                /> */}
                  <CollabolatorsAvatar
                    src={
                      user?.instance_type === 'Air 1 Camera' ||
                      user?.instance_type === 'AIR One™'
                        ? CameraIcon
                        : InsIcon
                    }
                    is_deactivated={user.is_deactivated}
                  />
                  <div>
                    <EachCollabolatorsP is_deactivated={user.is_deactivated}>
                      {/* Deprecating looking inside `user?.camera` object, as after BE update the data outside is more updated */}
                      {user?.nick_name ?? user?.camera?.nick_name}
                    </EachCollabolatorsP>
                    <EachCollabolatorsP6P is_deactivated={user.is_deactivated}>
                      {user?.air_id ?? user?.camera?.air_id}
                    </EachCollabolatorsP6P>
                  </div>
                </EachCollabolatorsP1>
                <EachCollabolatorsP2>
                  <EachCollabolatorsP2Div is_deactivated={user.is_deactivated}>
                    <div>
                      <p>
                        {typeOptions.find(
                          type => type?.value === user?.instance_type,
                        )?.label || user?.instance_type}
                      </p>
                    </div>
                  </EachCollabolatorsP2Div>
                </EachCollabolatorsP2>
                <EachCollabolatorsP3>
                  <EachCollabolatorsP3P is_deactivated={user.is_deactivated}>
                    {user?.package_info?.price &&
                      `$${user?.package_info?.price / 100}`}{' '}
                    {user?.package_info?.price_supporting_text}
                  </EachCollabolatorsP3P>
                </EachCollabolatorsP3>
                <EachCollabolatorsP4 is_deactivated={user.is_deactivated}>
                  {user?.end_date &&
                    moment(user.end_date).format('MMM Do, YYYY')}
                </EachCollabolatorsP4>
                <EachCollabolatorsP5 onClick={() => editGear(user, index)}>
                  <EachCollabolatorsP5P>Edit</EachCollabolatorsP5P>
                </EachCollabolatorsP5>
              </EachCollabolators>
            );
          })}
      </Collabolators>
      <SearchFooter
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        prePage={prePage}
        nextPg={nextPg}
        count={gearsList?.length || 0}
      />
    </>
  );
};

export default GearsList;
