import React, { useState, useEffect } from 'react';
import {
  Btn2,
  EditCamTimeImg,
  OptionsValueContainer,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RightNavLine,
  RightNavSecDiv,
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSDiv4,
  SBMSDiv5,
  SBMSImg,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SDMHeadingPWhite,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
} from '../styles';
import cross from '../../../Assets/images/cross.png';
import time from '../../../Assets/images/time.png';
import binWhite from '../../../Assets/images/binWhite.png';
import tick from '../../../Assets/images/tick.png';
import Select, { components } from 'react-select';
import { isIP } from 'is-ip';
import {
  DropDownIconContainer,
  OptionText,
  SelectedValueContainer,
} from '../../../Components/CommonStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiGearPostRequest,
  apiGetCameraDetailRequest,
  clearcameraDetails,
} from 'Redux/actions/gear';
import { apiGetAllAssetsRequest } from 'Redux/actions/assets';
import {
  Int_Rec_Format_Options2,
  Rec_Format_Options1,
  Rec_Format_Options2,
  ext_Rec_Format_Options3,
  int_Rec_Format_Options3,
} from 'Config/permissionConstant';

const OnboardCamera = ({
  onChangeRightNav,
  editUser,
  setOnboardForm,
  organisationId,
  organisationName,
}) => {
  const auth = useSelector(state => state.auth);
  const gears = useSelector(state => state.gear);
  const dispatch = useDispatch();
  const [airID, setAirId] = useState('');
  // let organisation = auth.user_information.organisation_data[0].organisation.id;
  let organisation = organisationId;

  const [optionSelect, setoptionSelect] = useState(editUser?.role);
  const [internal_record, set_internal_record] = useState({
    part1: '1080',
    part2: '23.98',
    part3: 'h.264',
  });
  const [external_record, set_external_record] = useState({
    part1: '1080',
    part2: 'p24',
    part3: 'h.264',
  });
  let internal_rec_format = '';
  let external_rec_format = '';
  const [formObj, setFormObj] = useState({
    owner_name: organisationName,
    auto_update: true,
  });

  const [error, setErrors] = useState({});
  const checkCameraValidity = e => {
    const val = e.target.value
      .replace(/^([A-Za-z0-9]{4})$/g, '$1-')
      .replace(/^([A-Za-z0-9]{4}-[A-Za-z0-9]{4})$/g, '$1-');
    setAirId(val);
    setFormObj(prev => ({ ...prev, air_id: val }));
  };
  const validateForm = () => {
    const newErrors = {};

    if (!formObj.air_id) {
      newErrors.air_id = 'This field is required';
    } else if (formObj.air_id.length !== 14) {
      newErrors.air_id = 'Please provide a valid Air Id';
    }

    if (!formObj.nick_name) {
      newErrors.nick_name = 'This field is required';
    }
    const nickNameRegex = /^[a-zA-Z0-9\s]+$/;
    if (nickNameRegex.test(formObj.nick_name) === false) {
      newErrors.nick_name = 'Nickname must only contains letters, numbers, and spaces.';
    }

    if (formObj?.internal_record_format) {
      if (
        !internal_record.part1 ||
        !internal_record.part2 ||
        !internal_record.part3
      ) {
        newErrors.internal_record = 'Internal record format is not valid';
      }
    }

    if (formObj?.external_stream_format) {
      if (
        !external_record.part1 ||
        !external_record.part2 ||
        !external_record.part3
      ) {
        newErrors.external_record = 'External record format is not valid';
      }
    }

    if (formObj.lan_ip && !isIP(formObj.lan_ip)) {
      newErrors.lan_ip = 'Invalid LAN IP address';
    }

    if (formObj.public_ip && !isIP(formObj.public_ip)) {
      newErrors.public_ip = 'Invalid WAN IP address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onConfirm = () => {
    const isValid = validateForm();

    if (isValid) {
      onChangeRightNav('cameraPackages');
      formObj['organisation'] = organisation;
      setOnboardForm(formObj);
      dispatch(clearcameraDetails());
    }

    // console.log('organisation ONB', formObj);
  };

  const closeSideBar = () => {
    onChangeRightNav('');
    dispatch(clearcameraDetails());
  };

  // useEffect(() => {
  //   if (formObj?.air_id?.length < 14) {
  //     error.air_id = false;
  //   }
  //   // else if (formObj?.nick_name) {
  //   //   error.nick_name = false;
  //   //   setErrorTxt('');
  //   // }
  // }, [formObj?.air_id]);

  const splitFormat = format => {
    return format?.split('/') || [];
  };

  const setInternalRecordFormat = camera => {
    const internalRecordFormat = camera.internal_record_format;
    if (internalRecordFormat) {
      const [part1, part2, part3] = splitFormat(internalRecordFormat);
      set_internal_record({ part1, part2, part3 });
    }
  };

  const setExternalRecordFormat = camera => {
    const externalStreamFormat = camera.external_stream_format;
    if (externalStreamFormat) {
      const [part1, part2, part3] = splitFormat(externalStreamFormat);
      set_external_record({ part1, part2, part3 });
    }
  };

  useEffect(() => {
    if (
      !internal_record.part1 ||
      !internal_record.part2 ||
      !internal_record.part3
    ) {
      setErrors(prev => ({
        ...prev,
        internal_record: 'Internal record format is not valid',
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        internal_record: '',
      }));
    }
    if (
      !external_record.part1 ||
      !external_record.part2 ||
      !external_record.part3
    ) {
      setErrors(prev => ({
        ...prev,
        external_record: 'External record format is not valid',
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        external_record: '',
      }));
    }
  }, [internal_record, external_record]);

  useEffect(() => {
    if (gears?.cameraDetails) {
      setFormObj(prev => ({
        ...prev,
        owner_name: gears?.cameraDetails?.owner_name || organisationName,
        nick_name: gears?.cameraDetails?.nick_name,
        lan_ip: gears?.cameraDetails?.lan_ip,
        public_ip: gears?.cameraDetails?.public_ip,
        auto_update: gears?.cameraDetails?.auto_update,
        // stream_destination_ip: gears?.cameraDetails?.stream_destination_ip,
      }));
      setErrors(prev => ({
        ...prev,
        air_id: null,
      }));
      if (gears?.cameraDetails?.internal_record_format) {
        setInternalRecordFormat(gears?.cameraDetails);
      }

      if (gears?.cameraDetails?.external_stream_format) {
        setExternalRecordFormat(gears?.cameraDetails);
      }
    } else if (gears?.cameraDetailsErr) {
      setErrors(prev => ({
        ...prev,
        air_id: gears?.cameraDetailsErr,
      }));
      setFormObj(prev => ({
        ...prev,
        owner_name: organisationName,
        nick_name: '',
        lan_ip: '',
        public_ip: '',
        auto_update: false,
        // stream_destination_ip: '',
      }));
    }
  }, [gears?.cameraDetails, gears?.cameraDetailsErr]);

  useEffect(() => {
    if (formObj?.air_id?.length >= 14) {
      dispatch(apiGetCameraDetailRequest(formObj?.air_id, auth.token));
    }
  }, [formObj?.air_id]);

  const cameraOptions = [{ value: 'air_one', label: 'AIR One™' }];
  // const Rec_Format_Options1 = [
  //   { value: '1080', label: '1080' },
  //   { value: '4k', label: '4k' },
  // ];
  // const Rec_Format_Options2 = [
  //   { value: 'p25', label: 'p25' },
  //   { value: 'p30', label: 'p30' },
  //   { value: 'p50', label: 'p50' },
  //   { value: 'p60', label: 'p60' },
  //   { value: '25', label: '25' },
  //   { value: '30', label: '30' },
  //   { value: '50', label: '50' },
  //   { value: '60', label: '60' },
  // ];

  // const int_Rec_Format_Options3 = [
  //   { value: 'ProRes', label: 'ProRes' },
  //   { value: 'ProResHQ', label: 'ProResHQ' },
  // ];

  // const ext_Rec_Format_Options3 = [
  //   { value: 'h.264', label: 'h.264' },
  //   { value: 'h.265', label: 'h.265' },
  // ];

  useEffect(() => {
    internal_rec_format = `${internal_record.part1}/${internal_record.part2}/${internal_record.part3}`;
    if (
      internal_record.part1 ||
      internal_record.part2 ||
      internal_record.part3
    ) {
      setFormObj(prev => ({
        ...prev,
        internal_record_format: internal_rec_format,
      }));
    }
  }, [internal_record]);

  useEffect(() => {
    external_rec_format = `${external_record.part1}/${external_record.part2}/${external_record.part3}`;
    if (
      external_record.part1 ||
      external_record.part2 ||
      external_record.part3
    ) {
      setFormObj(prev => ({
        ...prev,
        external_stream_format: external_rec_format,
      }));
    }
  }, [external_record]);

  const customRoleStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '100%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '90%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };

  const airTypeStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '100%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };

  const ValueContainer = props => {
    return (
      <components.Option {...props}>
        <OptionsValueContainer>
          <OptionText>{props.data.label}</OptionText>
          {props.data.label === optionSelect || props.isSelected ? (
            <img src={tick} style={{ width: 13.33, height: 9.17 }} />
          ) : null}
        </OptionsValueContainer>
      </components.Option>
    );
  };

  const DropDownIcon = props => (
    <DropDownIconContainer>
      <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1 1.5 5 5 5-5"
          stroke="#667085"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </DropDownIconContainer>
  );
  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Onboard Camera</SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv
          style={{
            padding: '5px',
            marginBottom: '70px',
            paddingBottom: '50px',
          }}
        >
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Camera Type:</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                defaultValue={{ value: 'air_one', label: 'AIR One™' }}
                // onChange={handleRoleFilter}
                options={cameraOptions}
                placeholder={editUser?.role ? editUser?.role : 'AIR One™'}
                styles={airTypeStyles}
                // onChange={e => setoptionSelect(e.label)}
                // onChange={e => setFormObj(prev=> ({...prev,type: e.label}))}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
                isSearchable={false}
              />
            </SBMSDiv3a>
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="0000-0000-0000"
                maxLength={14}
                // onChange={() => set}
                value={airID}
                onChange={checkCameraValidity}

                // defaultValue={editUser?.user_info.email}
              />
            </SBMSDiv3>
            {error?.air_id && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.air_id}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Owner"
                maxLength={30}
                readOnly
                value={formObj.owner_name}
                // onChange={e =>
                //   setFormObj(prev => ({ ...prev, owner_name: e.target.value }))
                // }
                // defaultValue={editUser?.user_info.first_name}
                style={{ color: '#667085' }}
              />
            </SBMSDiv3>
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Nickname"
                maxLength={30}
                onChange={e =>
                  setFormObj(prev => ({ ...prev, nick_name: e.target.value }))
                }
                value={formObj?.nick_name || ''}
                // defaultValue={editUser?.user_info.last_name}
              />
            </SBMSDiv3>
            {error?.nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.nick_name}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="auto_update"
              onChange={e =>
                setFormObj(prev => ({ ...prev, auto_update: e.target.checked }))
              }
              defaultChecked={true}
            />
            <label
              className="form-label form-check-label"
              htmlFor="auto_update"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Lan IP / WAN IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Lan IP</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              className="form-control"
              placeholder="-"
              maxLength={30}
              value={formObj.lan_ip}
              onChange={e => {
                if (e.target.value.length > 0 && !isIP(e.target.value)) {
                  setErrors(prev => ({
                    ...prev,
                    lan_ip: 'Invalid LAN IP address',
                  }));
                  setFormObj(prev => ({ ...prev, lan_ip: e.target.value }));
                } else {
                  setFormObj(prev => ({ ...prev, lan_ip: e.target.value }));
                  setErrors(prev => ({ ...prev, lan_ip: null }));
                }
              }}
              disabled={formObj.auto_update}
            />
            {error?.lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.lan_ip}
                </p>
              </div>
            )}
            {/* </SBMSDiv3> */}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              placeholder="-"
              maxLength={30}
              className="form-control"
              value={formObj.public_ip}
              onChange={e => {
                if (e.target.value.length > 0 && !isIP(e.target.value)) {
                  setErrors(prev => ({
                    ...prev,
                    public_ip: 'Invalid WAN IP address',
                  }));
                  setFormObj(prev => ({ ...prev, public_ip: e.target.value }));
                } else {
                  setFormObj(prev => ({ ...prev, public_ip: e.target.value }));
                  setErrors(prev => ({ ...prev, public_ip: null }));
                }
              }}
              disabled={formObj.auto_update}
            />
            {error?.public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.public_ip}
                </p>
              </div>
            )}
            {/* </SBMSDiv3> */}
          </div>

          {/* <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Internal Record Format:</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.label,
                  }));
                }}
                options={Rec_Format_Options1}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
              <Select
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.label,
                  }));
                }}
                options={Int_Rec_Format_Options2}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
              <Select
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.label,
                  }));
                }}
                options={int_Rec_Format_Options3}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
            </SBMSDiv3a>
            {error.internal_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>External Stream Format:</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part1: e.label,
                  }));
                }}
                options={Rec_Format_Options1}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
              <Select
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part2: e.label,
                  }));
                }}
                options={Rec_Format_Options2}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
              <Select
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part3: e.label,
                  }));
                }}
                options={ext_Rec_Format_Options3}
                placeholder={'Format'}
                styles={customRoleStyles}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
            </SBMSDiv3a>
            {error.external_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div> */}

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Internal Record Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Int_Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {int_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error?.internal_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.internal_record}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>External Stream Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
              >
                {Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
              >
                {ext_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error?.external_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.external_record}
                </p>
              </div>
            )}
          </div>
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={onConfirm}>Confirm</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default OnboardCamera;
