import styled from 'styled-components';

export const AddCrewContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DoneButton = styled.button`
  margin-left: 12px;
`;

export const NavFooter = styled.div`
  height: 72px;
  border-top: 1px solid #e4e7ec;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AddGearFormContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  max-height: 100%;
  overflow: auto;
  padding: 0px 24px;
  margin: 24px 0px 0px 0px;
`;
