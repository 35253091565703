import {
  put,
  call,
  all,
  spawn,
  takeEvery,
  delay,
  takeLatest,
} from 'redux-saga/effects';
import { onlineStatusServices } from '../services/onlineStatus';
import * as types from '../constants/onlineStatus';
import * as actions from '../actions/onlineStatus';

// Get Camera Status
function* apiGetCameraStatusWorker(action) {
  try {
    const result = yield call(onlineStatusServices.apiGetCameraStatus, action);
    yield put(actions.apiGetCameraStatusSuccess(result, action));
    // yield delay(1 * 60 * 1000);
    // yield delay(4 * 60 * 1000);
    if (
      window.location.pathname.includes('launchpad') ||
      window.location.pathname.includes('shoot-setup')
    ) {
      // yield delay(500);
      yield delay(30 * 1000);
      yield put(
        actions.apiGetCameraStatusRequest(action.air_ids, action.token),
      );
    }
  } catch (err) {
    yield put(actions.apiGetCameraStatusFailed(err, action));
  }
}

function* apiGetCameraStatusWatcher() {
  yield takeLatest(
    types.API_GET_CAMERA_STATUS_REQUEST,
    apiGetCameraStatusWorker,
  );
}

// update user online Status
function* apiUpdateUserOnlineStatusWorker(action) {
  try {
    const result = yield call(
      onlineStatusServices.apiUpdateUserOnlineStatus,
      action,
    );
    yield put(actions.apiUpdateUserOnlineStatusSuccess(result));
  } catch (err) {
    yield put(actions.apiUpdateUserOnlineStatusFailed(err, action));
  }
}

function* apiUpdateUserOnlineStatusWatcher() {
  yield takeLatest(
    types.API_POST_USER_ONLINE_STATUS_REQUEST,
    apiUpdateUserOnlineStatusWorker,
  );
}

// Read more information about root sagas in the documentation
// https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* onlineStatusRootSaga() {
  const sagas = [apiGetCameraStatusWatcher, apiUpdateUserOnlineStatusWatcher];

  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      }),
    ),
  );
}
