// export const appConfig = {
//   API_BASE_URL: window.location.hostname.includes('staging')
//     ? 'https://advanced-image-robo-32-staging.botics.co'
//     : !window.location.hostname.includes('localhost')
//     ? 'http://localhost:8000'
//     : 'https://advanced-image-robo-32516.botics.co', // uncomment before deploy
//   // : 'https://advanced-image-robo-32-staging.botics.co', // remove  before deploy

//   defaultTimeout: 5000,
// };

const getBaseURL = () => {
  return window.location.origin;
};

export const appConfig = {
  API_BASE_URL: getBaseURL(),
  defaultTimeout: 5000,
};

export const CAMERA_STATUS_COLORS = {
  GREEN: '#12B76A',
  GOLD: '#FBC348',
  RED: '#FF5C5C',
  GREY: '#D0D5DD',
  BLACK: '#000000',
};
