import Loader from 'Components/Loader';
import React, { useEffect, useState } from 'react';
import { OutlinedButton } from '../IconButton/styles';
import {
  AddCrewContentContainer,
  DoneButton,
  MembersSearchList,
  NavFooter,
} from './styles';
import Badge from 'Components/Badge';
import _ from 'loadsh';
import { BorderBottom } from 'Components/CommonStyles';
import X from 'Components/Icons/X';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { apiGetAllMediaRequest } from 'Redux/actions/media';
import CustomOutsideSelect from 'Pages/AssetDetailsPage/components/CustomOutsideSelect';
import { appConfig } from 'Config/app';

const AddtoTagsContent = ({
  onRightNavClose,
  isLoading,
  keys,
  metadata,
  selectedOrg,
  handleCopyObject,
  tagsList,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [value, setValue] = useState('');
  // const [tags, setTags] = useState(new Set());
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTags(
      keys.reduce((acc, item) => {
        const findData = metadata.find(
          obj => obj?.metadata?.Metadata?.key === item,
        );
        JSON.parse(findData?.metadata?.Metadata?.tags);
        const tagsList =
          [
            ...new Set([
              ...JSON.parse(findData?.metadata?.Metadata?.tags || '[]').map(
                tag => JSON.stringify(tag || {}),
              ),
            ]),
          ]?.map(tag => {
            tag = JSON.parse(tag) || {};
            return { value: Number(tag?.id), label: tag?.name };
          }) || [];

        return [...acc, ...tagsList];
      }, []),
    );
  }, [keys, metadata]);

  const addNewTag = async newTag => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${auth.token}`,
      },
      body: JSON.stringify(newTag),
    };
    const response = await fetch(
      appConfig.API_BASE_URL + `/api/v1/organisation/media/tags/`,
      requestOptions,
    );
    const data = await response.json();
    return data;
  };
  const handleSubmit = () => {
    setLoader(true);
    keys.forEach(async item => {
      const findData = metadata.find(
        obj => obj?.metadata?.Metadata?.key === item,
      );
      let updatedTags = tags?.map(item => ({
        id: item.value,
        name: item.label,
      }));

      for (const item of tags) {
        if (item?.__isNew__) {
          const res = await addNewTag({
            name: item.label,
          });
          if (res) {
            updatedTags = updatedTags.filter(col => col.id !== item.value);
            updatedTags.push({
              id: res.id,
              name: res.name,
            });
          }
        }
      }
      console.log(
        'updatedTags: ',
        ...updatedTags,
        ' JSON TAGS : ',
        ...JSON.parse(findData?.metadata?.Metadata?.tags),
      );
      const updatedMetadata = {
        ...findData?.metadata?.Metadata,
        tags: JSON.stringify(
          [
            ...new Set([
              ...updatedTags.map(tag => JSON.stringify(tag)),
              ...JSON.parse(findData?.metadata?.Metadata?.tags).map(tag =>
                JSON.stringify(tag),
              ),
            ]),
          ].map(tag => JSON.parse(tag)),
        ),
      };
      console.log('update : ', updatedMetadata, 'met : ', metadata);
      await handleCopyObject(
        selectedOrg.label,
        selectedOrg.label,
        item,
        updatedMetadata,
      );
    });

    // setTimeout(() => {
    setLoader(false);
    // toast.success('Tags added successfully');
    dispatch(apiGetAllMediaRequest(auth.token, selectedOrg.value));
    onRightNavClose();
    // }, 3000);
  };

  const handleTagChange = values => {
    setTags(values);
  };
  return (
    <AddCrewContentContainer>
      {loader === false ? (
        <MembersSearchList>
          <div className="mb-3">
            <label htmlFor="client" className="form-label">
              Tags
            </label>
            {/* <input
              type="text"
              className="form-control"
              placeholder="Enter Tag"
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTags(
                    previousState =>
                      new Set([...previousState, e.target.value]),
                  );
                  setValue('');
                }
              }}
              onBlur={e => {
                if (e.target.value.length > 0) {
                  setTags(
                    previousState =>
                      new Set([...previousState, e.target.value]),
                  );
                  setValue('tags', '');
                }
              }}
            /> */}
            <CustomOutsideSelect
              onChange={handleTagChange}
              isMulti
              isCreatable={true}
              options={
                tagsList
                  ?.sort((a, b) =>
                    a?.name
                      ?.toLowerCase()
                      ?.localeCompare(b?.name?.toLowerCase(), 'en', {
                        sensitivity: 'base',
                      }),
                  )
                  ?.map(item => {
                    return { value: Number(item.id), label: item.name };
                  }) || []
              }
              value={tags}
            />
            {/* <Stack
              direction="row"
              flexWrap={'wrap'}
              gap={'8px'}
              maxWidth={'400px'}
              className="mt-3"
            >
              {[...tags].map((item, index) => (
                <Badge
                  title={item}
                  key={index}
                  suffix={
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setTags(
                          prev => new Set([...prev].filter(x => x !== item)),
                        )
                      }
                    >
                      <X />
                    </div>
                  }
                  customStyle={{ borderRadius: '8px', height: '36px' }}
                />
              ))}
            </Stack> */}
          </div>
          <BorderBottom />
        </MembersSearchList>
      ) : (
        <Loader />
      )}

      <NavFooter>
        <OutlinedButton className="btn btn-sm" onClick={onRightNavClose}>
          Cancel
        </OutlinedButton>
        <DoneButton
          className="btn btn-primary btn-sm"
          onClick={handleSubmit}
          disabled={!tags.length > 0}
        >
          Confirm
        </DoneButton>
      </NavFooter>
    </AddCrewContentContainer>
  );
};

export default AddtoTagsContent;
