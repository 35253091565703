import React, { useState } from 'react';
import {
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  RightNavSecDiv,
  TeamHeadBtnImgPa,
  OptionsValueContainer,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  SBMSDiv4,
  SBMSDiv5,
  SDMHeadingPWhite,
  RightNavLine,
  EditCamTimeImg,
} from './styles';
import cross from '../../Assets/images/cross.png';
import binWhite from '../../Assets/images/binWhite.png';
import time from '../../Assets/images/time.png';
import tick from '../../Assets/images/tick.png';
import errorIcon from '../../Assets/icons/errorIcon.svg';
import Select, { components } from 'react-select';
import {
  DropDownIconContainer,
  OptionText,
  SelectedValueContainer,
} from '../CommonStyles';
import moment from 'moment';
import { useEffect } from 'react';
import Modal from '../../Components/Modal';
import { ModalFooter } from '../../Components/CommonStyles';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiGetCameraDeactivateRequest,
  apiGetCameraDetailRequest,
  clearcameraDetails,
} from 'Redux/actions/gear';
import { toast } from 'react-toastify';
import CustomSelect from 'Components/CustomSelect';
import {
  Int_Rec_Format_Options2,
  Rec_Format_Options1,
  Rec_Format_Options2,
  ext_Rec_Format_Options3,
  int_Rec_Format_Options3,
} from 'Config/permissionConstant';
import { isIP } from 'is-ip';

const EditCamera = ({
  onChangeRightNav,
  setSideNavPage,
  setOnboardForm,
  editCamera,
  onGearEditApi,
  closeSideRightNav,
  closeSideBar,
  organisationId,
  ...props
}) => {
  const cameraOptions = [{ value: 'air_one', label: 'AIR One™' }];
  const dispatch = useDispatch();
  let internal_rec_format = '';
  let external_rec_format = '';
  const auth = useSelector(state => state.auth);
  const gear = useSelector(state => state.gear);
  const cameraDetails = useSelector(state => state.cameraDetails);
  /* Deprecating looking inside `user?.camera` object, as after BE update the data outside is more updated */
  const { camera: _, ...objExclCamera } = editCamera;
  const [camera, setCamera] = useState(
    editCamera?.camera
      ? { ...editCamera?.camera, ...objExclCamera }
      : editCamera,
  );
  const [error, setError] = useState({
    nick_name: null,
    internal_record: null,
    external_record: null,
    lan_ip: null,
    public_ip: null,
  });
  const [internal_record, set_internal_record] = useState({
    part1: '',
    part2: '',
    part3: '',
  });
  const [external_record, set_external_record] = useState({
    part1: '',
    part2: '',
    part3: '',
  });
  const endDate = editCamera?.organisation_owned_camera?.end_date
    ? moment(editCamera?.organisation_owned_camera?.end_date).format(
        'MMMM DD YYYY',
      )
    : editCamera?.end_date
    ? moment(editCamera?.end_date).format('MMMM DD YYYY')
    : null;
  const isExpired = endDate ? moment(endDate).isBefore(moment()) : false;
  const disableEdit = isExpired || editCamera?.is_deactivated;
  const nickNameRegex = /^[a-zA-Z0-9\s]+$/;

  // const Rec_Format_Options1 = [
  //   { value: '1080', label: '1080' },
  //   { value: '4k', label: '4k' },
  // ];
  // const Rec_Format_Options2 = [
  //   { value: 'p25', label: 'p25' },
  //   { value: 'p30', label: 'p30' },
  //   { value: 'p50', label: 'p50' },
  //   { value: 'p60', label: 'p60' },
  //   { value: '25', label: '25' },
  //   { value: '30', label: '30' },
  //   { value: '50', label: '50' },
  //   { value: '60', label: '60' },
  // ];

  // const int_Rec_Format_Options3 = [
  //   { value: 'ProRes', label: 'ProRes' },
  //   { value: 'ProResHQ', label: 'ProResHQ' },
  // ];

  // const ext_Rec_Format_Options3 = [
  //   { value: 'h.264', label: 'h.264' },
  //   { value: 'h.265', label: 'h.265' },
  // ];

  const customRoleStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '25%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '90%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };
  const typeStyles = {
    option: (provided, state) => ({
      ...provided,
      width: '100%',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };
  // ================ Owner  Name ===========================
  // useEffect(() => {
  //   if (gear?.cameraDetails) {
  //     editCamera.owner_name = cameraDetails;
  //   }
  // }, [gear?.cameraDetails]);

  const splitFormat = format => {
    return format.split('/');
  };

  const setInternalRecordFormat = camera => {
    const internalRecordFormat =
      // (camera?.camera && camera.camera.internal_record_format) ||
      camera.internal_record_format;
    if (internalRecordFormat) {
      const [part1, part2, part3] = splitFormat(internalRecordFormat);
      set_internal_record({ part1, part2, part3 });
    }
  };

  const setExternalRecordFormat = camera => {
    const externalStreamFormat =
      // (camera?.camera && camera.camera.external_stream_format) ||
      camera.external_stream_format;
    if (externalStreamFormat) {
      const [part1, part2, part3] = splitFormat(externalStreamFormat);
      set_external_record({ part1, part2, part3 });
    }
  };

  useEffect(() => {
    const air_id_here = editCamera.air_id
      ? editCamera.air_id
      : editCamera.camera.air_id;
    if (editCamera?.camera?.air_id && auth) {
      dispatch(apiGetCameraDetailRequest(air_id_here, auth.token));
    } else if (!editCamera?.owner_name && auth) {
      dispatch(apiGetCameraDetailRequest(air_id_here, auth.token));
    }

    // Refactored code
    if (
      editCamera?.internal_record_format 
      // || editCamera?.camera?.internal_record_format
    ) {
      setInternalRecordFormat(editCamera);
    }

    if (
      editCamera?.external_stream_format 
      // || editCamera?.camera?.external_stream_format
    ) {
      setExternalRecordFormat(editCamera);
    }
  }, []);

  useEffect(() => {
    if (gear?.cameraDetails) {
      setCamera(prev => ({
        ...prev,
        owner_name: gear?.cameraDetails.owner,
        lan_ip: gear?.cameraDetails.lan_ip,
        public_ip: gear?.cameraDetails.public_ip,
        auto_update: gear?.cameraDetails?.auto_update,
        // stream_destination_ip: gear?.cameraDetails?.stream_destination_ip,
      }));
    }
  }, [gear?.cameraDetails]);
  // =================================================

  useEffect(() => {
    internal_rec_format = `${internal_record.part1}/${internal_record.part2}/${internal_record.part3}`;
    if (
      internal_record.part1 ||
      internal_record.part2 ||
      internal_record.part3
    ) {
      setCamera(prev => ({
        ...prev,
        internal_record_format: internal_rec_format,
      }));
    }
  }, [internal_record]);

  useEffect(() => {
    external_rec_format = `${external_record.part1}/${external_record.part2}/${external_record.part3}`;
    if (
      external_record.part1 ||
      external_record.part2 ||
      external_record.part3
    ) {
      setCamera(prev => ({
        ...prev,
        external_stream_format: external_rec_format,
      }));
    }
  }, [external_record]);

  // useEffect(() => {
  //   if (
  //     camera?.internal_record_format !== '' ||
  //     camera?.camera?.internal_record_format !== ''
  //   ) {
  //     set_internal_record({
  //       part1: editCamera?.camera
  //         ? editCamera?.camera.internal_record_format.split('/')[0]
  //         : editCamera?.internal_record_format.split('/')[0],
  //       part2: editCamera?.camera
  //         ? editCamera?.camera.internal_record_format.split('/')[1]
  //         : editCamera?.internal_record_format.split('/')[1],
  //       part3: editCamera?.camera
  //         ? editCamera?.camera.internal_record_format.split('/')[2]
  //         : editCamera?.internal_record_format.split('/')[2],
  //     });
  //   }
  //   if (
  //     editCamera?.external_stream_format !== '' ||
  //     editCamera?.camera?.external_stream_format !== ''
  //   ) {
  //     set_external_record({
  //       part1: editCamera?.camera
  //         ? editCamera?.camera.external_stream_format.split('/')[0]
  //         : editCamera?.external_stream_format.split('/')[0],
  //       part2: editCamera?.camera
  //         ? editCamera?.camera.external_stream_format.split('/')[1]
  //         : editCamera?.external_stream_format.split('/')[1],
  //       part3: editCamera?.camera
  //         ? editCamera?.camera.external_stream_format.split('/')[2]
  //         : editCamera?.external_stream_format.split('/')[2],
  //     });
  //   }
  // }, []);

  const validateCamera = () => {
    if (!camera.air_id) {
      setError(prev => ({ ...prev, air_id: 'This field is required' }));
      return;
    }
    if (!camera.nick_name || !camera.nick_name === '') {
      setError(prev => ({ ...prev, nick_name: 'This field is required' }));
      return;
    }
    if (camera?.internal_record_format) {
      if (
        !internal_record.part1 ||
        !internal_record.part2 ||
        !internal_record.part3
      ) {
        setError(prev => ({
          ...prev,
          internal_record: 'Internal record format is not valid',
        }));
        return;
      }
    }
    if (camera?.external_stream_format) {
      if (
        !external_record.part1 ||
        !external_record.part2 ||
        !external_record.part3
      ) {
        setError(prev => ({
          ...prev,
          external_record: 'External record format is not valid',
        }));
        return;
      }
    }
    if (error?.lan_ip || error?.public_ip) return;

    return true;
  };

  // ================== De-Activate Modal ===============
  const deactivateCamera = () => {
    let isPaid = editCamera.organisation_owned_camera
      ? editCamera.organisation_owned_camera.is_paid
      : editCamera.is_paid;
    let isActive = editCamera.organisation_owned_camera
      ? editCamera.organisation_owned_camera.is_active
      : editCamera.is_active;
    if (!isActive && !isPaid) {
      setShowModal(false);
      closeSideBar();
      let toastId = null;
      toastId = toast.loading('Please wait...');

      toast.update(toastId, {
        render: 'Camera is neither active nor payment flow is completed ',
        type: 'error',
        isLoading: false,
        autoClose: 1000,
        closeOnClick: true,
      });
      return;
    }
    if (!isActive) {
      setShowModal(false);
      closeSideBar();
      let toastId = null;
      toastId = toast.loading('Please wait...');

      toast.update(toastId, {
        render: 'Camera is not yet activate',
        type: 'error',
        isLoading: false,
        autoClose: 1000,
        closeOnClick: true,
      });
      return;
    }
    if (!isPaid) {
      setShowModal(false);
      closeSideBar();
      let toastId = null;
      toastId = toast.loading('Please wait...');

      toast.update(toastId, {
        render: 'Payment flow is not yet completed ',
        type: 'error',
        isLoading: false,
        autoClose: 1000,
        closeOnClick: true,
      });
      return;
    }
    let air_id = editCamera.camera
      ? editCamera.camera.air_id
      : editCamera.air_id;
    let project_id = props.project_id ? props?.project_id : null;
    dispatch(
      apiGetCameraDeactivateRequest(
        air_id,
        auth.token,
        project_id,
        organisationId,
      ),
    );
    setShowModal(false);
    closeSideBar();
  };

  const extendSubscription = () => {
    if (validateCamera()) {
      setSideNavPage
        ? setSideNavPage?.('Camera Packages')
        : onChangeRightNav?.('cameraPackages');
      const formObj = {
        ...camera,
        payment_session_id:
          editCamera?.organisation_owned_camera?.payment_session_id ||
          editCamera?.payment_session_id,
      };

      formObj['organisation'] = organisationId;
      setOnboardForm(formObj);
      dispatch(clearcameraDetails());
    } else {
      return;
    }
  };

  const [showModal, setShowModal] = useState(false);
  let modalData = {
    title: editCamera?.camera
      ? editCamera?.camera.nick_name
      : editCamera.nick_name,
    message: `Are you sure you want to deactivate "${
      editCamera?.camera ? editCamera.camera.nick_name : editCamera.nick_name
    }" now?`,
  };
  const modalFooter = (
    <ModalFooter>
      <Button onClick={() => setShowModal(false)} variant="primary">
        No, on{' '}
        {editCamera?.organisation_owned_camera?.end_date
          ? moment(editCamera?.organisation_owned_camera?.end_date).format(
              'MMMM DD YYYY',
            )
          : editCamera?.end_date
          ? moment(editCamera?.end_date).format('MMMM DD YYYY')
          : 'its expiry '}
      </Button>
      <Button onClick={deactivateCamera} variant="danger">
        Deactivate now
      </Button>
    </ModalFooter>
  );
  // console.log('editcamera', editCamera);

  const onConfirm = () => {
    if (validateCamera()) {
      closeSideBar();
      onGearEditApi({ ...camera, id: editCamera.id });
    } else {
      return;
    }
  };

  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Edit Camera </SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Camera Type</SDMHeadingP>
            <SBMSDiv3a style={{ width: '100%' }}>
              <CustomSelect
                defaultValue={cameraOptions[0]}
                // onChange={handleRoleFilter}
                options={cameraOptions}
                placeholder={'Air One'}
                styles={typeStyles}
                disabled={disableEdit}
                // components={{
                //   IndicatorSeparator: null,
                //   DropdownIndicator: DropDownIcon,
                //   Option: ValueContainer,
                // }}
              />
            </SBMSDiv3a>
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              placeholder="Owner"
              className="form-control"
              defaultValue={camera.owner_name || editCamera?.camera?.owner_name}
              disabled
              style={{ color: '#667085' }}
              onChange={e =>
                setCamera(prev => ({
                  ...prev,
                  owner_name: e.target.value,
                }))
              }
            />
            {/* </SBMSDiv3> */}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              placeholder="Enter Nickname"
              className="form-control"
              defaultValue={
                camera?.camera ? camera?.camera.nick_name : camera?.nick_name
              }
              onChange={e => {
                if (e.target.value.length > 0 && nickNameRegex.test(e.target.value) === false) {
                  setError(prev => ({
                    ...prev,
                    nick_name: 'Nickname must only contains letters, numbers, and spaces.',
                  }));
                  setCamera(prev => ({
                    ...prev,
                    nick_name: e.target.value,
                  }))
                } else {
                  setCamera(prev => ({
                    ...prev,
                    nick_name: e.target.value,
                  }))
                  setError(prev => ({ ...prev, nick_name: null }));
                }
              }}
              disabled={disableEdit}
            />
            {error?.nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error?.nick_name}
                </p>
              </div>
            )}
            {/* </SBMSDiv3> */}
          </div>

          <div style={{ marginTop: 15, marginLeft: '5px', cursor: 'pointer' }}>
            <input
              type="checkbox"
              className="form-check-input"
              id="auto_update"
              onChange={e =>
                setCamera(prev => ({ ...prev, auto_update: e.target.checked }))
              }
              defaultChecked={
                camera?.camera
                  ? camera?.camera.auto_update
                  : camera?.auto_update
              }
              disabled={disableEdit}
            />
            <label
              className="form-label form-check-label"
              htmlFor="auto_update"
              style={{ marginLeft: '8px' }}
            >
              Auto Update Lan IP / WAN IP
            </label>
          </div>

          <div style={{ marginTop: 5 }}>
            <SDMHeadingP>Lan IP</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              className="form-control"
              placeholder="-"
              maxLength={30}
              defaultValue={
                camera?.camera ? camera?.camera.lan_ip : camera?.lan_ip
              }
              disabled={
                (camera?.camera
                  ? camera?.camera.auto_update
                  : camera?.auto_update) || disableEdit
              }
              onChange={e =>
                // setCamera(prev => ({
                //   ...prev,
                //   lan_ip: e.target.value,
                // }))
                {
                  if (e.target.value.length > 0 && !isIP(e.target.value)) {
                    setError(prev => ({
                      ...prev,
                      lan_ip: 'Invalid LAN IP address',
                    }));
                    setCamera(prev => ({
                      ...prev,
                      lan_ip: e.target.value,
                    }));
                  } else {
                    setCamera(prev => ({
                      ...prev,
                      lan_ip: e.target.value,
                    }));
                    setError(prev => ({ ...prev, lan_ip: null }));
                  }
                }
              }
            />
            {/* </SBMSDiv3> */}
            {error.lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.lan_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              placeholder="-"
              maxLength={30}
              className="form-control"
              defaultValue={
                camera?.camera ? camera?.camera.public_ip : camera?.public_ip
              }
              disabled={
                (camera?.camera
                  ? camera?.camera.auto_update
                  : camera?.auto_update) || disableEdit
              }
              onChange={e =>
                // setCamera(prev => ({
                //   ...prev,
                //   public_ip: e.target.value,
                // }))
                {
                  if (e.target.value.length > 0 && !isIP(e.target.value)) {
                    setError(prev => ({
                      ...prev,
                      public_ip: 'Invalid WAN IP address',
                    }));
                    setCamera(prev => ({
                      ...prev,
                      public_ip: e.target.value,
                    }));
                  } else {
                    setCamera(prev => ({
                      ...prev,
                      public_ip: e.target.value,
                    }));
                    setError(prev => ({ ...prev, public_ip: null }));
                  }
                }
              }
            />
            {/* </SBMSDiv3> */}
            {error.public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.public_ip}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            {/* <SBMSDiv3> */}
            <input
              type="text"
              placeholder="0000-0000-0000"
              className="form-control"
              // onChange={checkCameraValidity}
              maxLength={14}
              defaultValue={
                camera?.camera ? camera?.camera.air_id : camera?.air_id
              }
              disabled
            />
            {/* </SBMSDiv3> */}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Internal Record Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              {/* <CustomSelect
                defaultValue={{
                  value: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[0]
                    : editCamera?.internal_record_format.split('/')[0],
                  label: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[0]
                    : editCamera?.internal_record_format.split('/')[0],
                }}
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.label,
                  }));
                }}
                options={Rec_Format_Options1}
                placeholder={'Format'}
                styles={typeStyles}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!internal_record?.part1}>
                  --select--
                </option>
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              {/* <CustomSelect
                defaultValue={{
                  value: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[1]
                    : editCamera?.internal_record_format.split('/')[1],
                  label: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[1]
                    : editCamera?.internal_record_format.split('/')[1],
                }}
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.label,
                  }));
                }}
                styles={typeStyles}
                options={Int_Rec_Format_Options2}
                placeholder={'Format'}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!internal_record?.part2}>
                  --select--
                </option>
                {Int_Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              {/* <CustomSelect
                defaultValue={{
                  value: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[2]
                    : editCamera?.internal_record_format.split('/')[2],
                  label: editCamera?.camera
                    ? editCamera?.camera.internal_record_format.split('/')[2]
                    : editCamera?.internal_record_format.split('/')[2],
                }}
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.label,
                  }));
                }}
                options={int_Rec_Format_Options3}
                placeholder={'Format'}
                styles={typeStyles}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_internal_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!internal_record?.part3}>
                  --select--
                </option>
                {int_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === internal_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error.internal_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.internal_record}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>External Stream Format</SDMHeadingP>
            <SBMSDiv3a style={{ columnGap: '4px' }}>
              {/* <CustomSelect
                // defaultValue={{
                //   value: editCamera?.camera
                //     ? editCamera?.camera.external_stream_format.split('/')[0]
                //     : editCamera?.external_stream_format.split('/')[0],
                //   label: editCamera?.camera
                //     ? editCamera?.camera.external_stream_format.split('/')[0]
                //     : editCamera?.external_stream_format.split('/')[0],
                // }}
                value={{
                  value: external_record?.part1,
                  label: external_record?.part1,
                }}
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part1: e.label,
                  }));
                }}
                options={Rec_Format_Options1}
                placeholder={'Format'}
                styles={typeStyles}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part1: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!external_record?.part1}>
                  --select--
                </option>
                {Rec_Format_Options1.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part1}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              {/* <CustomSelect
                defaultValue={{
                  value: editCamera?.camera
                    ? editCamera?.camera.external_stream_format.split('/')[1]
                    : editCamera?.external_stream_format.split('/')[1],
                  label: editCamera?.camera
                    ? editCamera?.camera.external_stream_format.split('/')[1]
                    : editCamera?.external_stream_format.split('/')[1],
                }}
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part2: e.label,
                  }));
                }}
                styles={typeStyles}
                options={Rec_Format_Options2}
                placeholder={'Format'}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part2: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!external_record?.part2}>
                  --select--
                </option>
                {Rec_Format_Options2.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part2}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
              {/* <CustomSelect
                defaultValue={{
                  value: editCamera?.camera
                    ? editCamera?.camera.external_stream_format.split('/')[2]
                    : editCamera?.external_stream_format.split('/')[2],
                  label: editCamera?.camera
                    ? editCamera?.camera.external_stream_format.split('/')[2]
                    : editCamera?.external_stream_format.split('/')[2],
                }}
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part3: e.label,
                  }));
                }}
                options={ext_Rec_Format_Options3}
                placeholder={'Format'}
                styles={typeStyles}
              /> */}
              <select
                className="form-select"
                placeholder="Format"
                onChange={e => {
                  set_external_record(prev => ({
                    ...prev,
                    part3: e.target.value,
                  }));
                }}
                disabled={disableEdit}
              >
                <option disabled value="" selected={!external_record?.part3}>
                  --select--
                </option>
                {ext_Rec_Format_Options3.map(item => (
                  <option
                    value={item.value}
                    key={item.value}
                    selected={item.value === external_record?.part3}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </SBMSDiv3a>
            {error.external_record && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.external_record}
                </p>
              </div>
            )}
          </div>
          {/* -------------------- For Edit Component ----------------- */}
          <RightNavLine />
          {((editCamera?.organisation_owned_camera?.stripe_subscription_id &&
            editCamera?.organisation_owned_camera?.stripe_subscription_id !==
              'NA') ||
            (editCamera?.stripe_subscription_id &&
              editCamera?.stripe_subscription_id !== 'NA')) && (
            <>
              <div style={{ marginTop: 15 }}>
                <SDMHeadingP>Expiration Date</SDMHeadingP>
                <SBMSDiv3>
                  <SBMSInput2
                    type="text"
                    placeholder="MM/DD/YYYY"
                    maxLength={30}
                    readOnly
                    defaultValue={
                      editCamera?.organisation_owned_camera
                        ? moment(
                            editCamera?.organisation_owned_camera?.end_date,
                          ).format('MMMM DD YYYY')
                        : moment(editCamera?.end_date).format('MMMM DD YYYY')
                    }
                  />
                </SBMSDiv3>
              </div>
              {isExpired && !editCamera?.is_deactivated && (
                <SBMSDiv4
                  onClick={extendSubscription}
                  style={{ cursor: 'pointer' }}
                >
                  <EditCamTimeImg src={time} />
                  <SDMHeadingP>Extend Subscription</SDMHeadingP>
                </SBMSDiv4>
              )}
              <RightNavLine />
              {editCamera?.is_deactivated && (
                <SBMSDiv4
                  onClick={extendSubscription}
                  style={{ cursor: 'pointer' }}
                >
                  <EditCamTimeImg src={time} />
                  <SDMHeadingP>Reactivate Camera</SDMHeadingP>
                </SBMSDiv4>
              )}

              {!editCamera?.is_deactivated && !isExpired && (
                <SBMSDiv5
                  onClick={
                    editCamera?.organisation_owned_camera
                      ? editCamera?.organisation_owned_camera
                          ?.stripe_subscription_id
                        ? () => setShowModal(true)
                        : null
                      : editCamera?.stripe_subscription_id
                      ? () => setShowModal(true)
                      : null
                  }
                  stripe_subscription_id={
                    editCamera?.organisation_owned_camera
                      ? editCamera?.organisation_owned_camera
                          ?.stripe_subscription_id
                      : editCamera?.stripe_subscription_id
                  }
                >
                  <EditCamTimeImg src={binWhite} />
                  <SDMHeadingPWhite>Deactivate Camera</SDMHeadingPWhite>
                </SBMSDiv5>
              )}
            </>
          )}
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa
            onClick={() => {
              onConfirm();
            }}
          >
            Confirm
          </TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        icon={errorIcon}
        title={modalData?.title}
        body={modalData?.message}
        footer={modalFooter}
      />
    </RightNavContainer>
  );
};

export default EditCamera;
