import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  AddCrewContentContainer,
  AddGearFormContainer,
  NavFooter,
  DoneButton,
} from './styles';
import { FormErrorText } from '../CommonStyles';
import { OutlinedButton } from '../IconButton/styles';
import { apiEditProjectFilesRequest } from 'Redux/actions/project';

const EditProjectFileName = ({
  auth,
  onRightNavClose,
  projectId,
  filesData,
}) => {
  const dispatch = useDispatch();
  const fileName = (filesData?.file_name || filesData?.file)?.split('/')?.pop();
  const fileType = fileName?.split('.')?.pop();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      file_name: fileName?.split('.')?.[0],
    },
    mode: 'onSubmit',
  });

  const onEditFileNameFormSubmit = () => {
    handleSubmit(data => {
      const result = {
        id: filesData?.id,
        project_id: projectId,
        file_name: data.file_name + '.' + fileType,
      };
      dispatch(apiEditProjectFilesRequest(result, auth.token));
      onRightNavClose();
    })();
  };

  return (
    <AddCrewContentContainer>
      <AddGearFormContainer>
        <form>
          <div className="mb-3">
            <label htmlFor="file-name" className="form-label">
              File Name
            </label>

            <input
              {...register('file_name', {
                required: { value: true, message: 'This field is required' },
                pattern: {
                  value: /^[a-zA-Z0-9](?:[a-zA-Z0-9 _-]*[a-zA-Z0-9])?$/,
                  message:
                    'Please enter valid file name without extension. Can only contain letters, space, -, _, numbers',
                },
              })}
              type="text"
              className="form-control"
              placeholder="Add File Name"
            />

            {errors.file_name ? (
              <FormErrorText className="form-text">
                This field is required
              </FormErrorText>
            ) : null}
          </div>
          <div style={{ border: '1px solid #E4E7EC' }} className="my-3"></div>
        </form>
      </AddGearFormContainer>
      <NavFooter>
        <OutlinedButton className="btn btn-sm" onClick={onRightNavClose}>
          Cancel
        </OutlinedButton>
        <DoneButton
          className="btn btn-primary btn-sm"
          onClick={onEditFileNameFormSubmit}
        >
          Apply
        </DoneButton>
      </NavFooter>
    </AddCrewContentContainer>
  );
};

export default EditProjectFileName;
