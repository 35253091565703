export const API_GEAR_LIST_REQUEST = 'API_GEAR_LIST_REQUEST';
export const API_GEAR_LIST_SUCCESS = 'API_GEAR_LIST_SUCCESS';
export const API_GEAR_LIST_FAILED = 'API_GEAR_LIST_FAILED';

// Add project
export const API_GEAR_POST_REQUEST = 'API_GEAR_POST_REQUEST';
export const API_GEAR_POST_SUCCESS = 'API_GEAR_POST_SUCCESS';
export const API_GEAR_POST_FAILED = 'API_GEAR_POST_FAILED';

// reset
export const RESET_GEAR = 'RESET_GEAR';

export const API_GEAR_GLOBAL_LIST_REQUEST = 'API_GEAR_GLOBAL_LIST_REQUEST';
export const API_GEAR_GLOBAL_LIST_SUCCESS = 'API_GEAR_GLOBAL_LIST_SUCCESS';
export const API_GEAR_GLOBAL_LIST_FAILED = 'API_GEAR_GLOBAL_LIST_FAILED';

export const API_GEAR_ASSIGN_LIST_REQUEST = 'API_GEAR_ASSIGN_LIST_REQUEST';
export const API_GEAR_ASSIGN_LIST_SUCCESS = 'API_GEAR_ASSIGN_LIST_SUCCESS';
export const API_GEAR_ASSIGN_LIST_FAILED = 'API_GEAR_ASSIGN_LIST_FAILED';

export const API_GEAR_ASSIGN_REQUEST = 'API_GEAR_ASSIGN_REQUEST';
export const API_GEAR_ASSIGN_SUCCESS = 'API_GEAR_ASSIGN_SUCCESS';
export const API_GEAR_ASSIGN_FAILED = 'API_GEAR_ASSIGN_FAILED';

// set members Data
export const SET_MEMBERS_DATA = 'SET_MEMBERS_DATA';

// Edit Camera
export const API_CAMERA_PATCH_REQUEST = 'API_CAMERA_PATCH_REQUEST';
export const API_CAMERA_PATCH_SUCCESS = 'API_CAMERA_PATCH_SUCCESS';
export const API_CAMERA_PATCH_FAILED = 'API_CAMERA_PATCH_FAILED';

// Edit Instance
export const API_INSTANCE_PATCH_REQUEST = 'API_INSTANCE_PATCH_REQUEST';
export const API_INSTANCE_PATCH_SUCCESS = 'API_INSTANCE_PATCH_SUCCESS';
export const API_INSTANCE_DELETE_SUCCESS = 'API_INSTANCE_DELETE_SUCCESS';
export const API_INSTANCE_PATCH_FAILED = 'API_INSTANCE_PATCH_FAILED';

// Get Camera Details
export const API_GET_CAMERA_DETAILS_REQUEST = 'API_GET_CAMERA_DETAILS_REQUEST';
export const API_GET_CAMERA_DETAILS_SUCCESS = 'API_GET_CAMERA_DETAILS_SUCCESS';
export const API_GET_CAMERA_DETAILS_FAILED = 'API_GET_CAMERA_DETAILS_FAILED';

// Get Instance Details
export const API_GET_INSTANCE_DETAILS_REQUEST =
  'API_GET_INSTANCE_DETAILS_REQUEST';
export const API_GET_INSTANCE_DETAILS_SUCCESS =
  'API_GET_INSTANCE_DETAILS_SUCCESS';
export const API_GET_INSTANCE_DETAILS_FAILED =
  'API_GET_INSTANCE_DETAILS_FAILED';

// Camera Deactivate
export const API_POST_CAMERA_DEACTIVATE_REQUEST =
  'API_POST_CAMERA_DEACTIVATE_REQUEST';
export const API_POST_CAMERA_DEACTIVATE_SUCCESS =
  'API_POST_CAMERA_DEACTIVATE_SUCCESS';
export const API_POST_CAMERA_DEACTIVATE_FAILED =
  'API_POST_CAMERA_DEACTIVATE_FAILED';

// CLEAR_CAMERA_DETAILS
export const CLEAR_CAMERA_DETAILS = 'CLEAR_CAMERA_DETAILS';

// CLEAR_INSTANCE_DETAILS
export const CLEAR_INSTANCE_DETAILS = 'CLEAR_INSTANCE_DETAILS';

// ONBOARD_CAMERA_DETAILS
export const ONBOARD_CAMERA_DETAILS = 'ONBOARD_CAMERA_DETAILS';
