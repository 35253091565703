// Get user information
export const API_GET_USER_INFORMATION = 'API_GET_USER_INFORMATION';
export const API_GET_USER_INFORMATION_SUCCESS =
  'API_GET_USER_INFORMATION_SUCCESS';
export const API_GET_USER_INFORMATION_FAILED =
  'API_GET_USER_INFORMATION_FAILED';

// Get MyStoragePlan
export const API_GET_MY_STORAGE_PLAN = 'API_GET_MY_STORAGE_PLAN';
export const API_GET_MY_STORAGE_PLAN_SUCCESS =
  'API_GET_MY_STORAGE_PLAN_SUCCESS';
export const API_GET_MY_STORAGE_PLAN_FAILED = 'API_GET_MY_STORAGE_PLAN_FAILED';

// Get MyAssets
export const API_GET_MY_ASSETS = 'API_GET_MY_ASSETS';
export const API_GET_MY_ASSETS_SUCCESS = 'API_GET_MY_ASSETS_SUCCESS';
export const API_GET_MY_ASSETS_FAILED = 'API_GET_MY_ASSETS_FAILED';

// List user information
export const API_LIST_USER_INFORMATION = 'API_LIST_USER_INFORMATION';
export const API_LIST_USER_INFORMATION_SUCCESS =
  'API_LIST_USER_INFORMATION_SUCCESS';
export const API_LIST_USER_INFORMATION_FAILED =
  'API_LIST_USER_INFORMATION_FAILED';

// Add user information
export const API_POST_USER_INFORMATION = 'API_POST_USER_INFORMATION';
export const API_POST_USER_INFORMATION_SUCCESS =
  'API_POST_USER_INFORMATION_SUCCESS';
export const API_POST_USER_INFORMATION_FAILED =
  'API_POST_USER_INFORMATION_FAILED';

// Update user information
export const API_PUT_USER_INFORMATION = 'API_PUT_USER_INFORMATION';
export const API_PUT_USER_INFORMATION_SUCCESS =
  'API_PUT_USER_INFORMATION_SUCCESS';
export const API_PUT_USER_INFORMATION_FAILED =
  'API_PUT_USER_INFORMATION_FAILED';

// Patch user information
export const API_PATCH_USER_INFORMATION = 'API_PATCH_USER_INFORMATION';
export const API_PATCH_USER_INFORMATION_SUCCESS =
  'API_PATCH_USER_INFORMATION_SUCCESS';
export const API_PATCH_USER_INFORMATION_FAILED =
  'API_PATCH_USER_INFORMATION_FAILED';

// Get oneline members
export const API_GET_ONLINE_MEMBERS = 'API_GET_ONLINE_MEMBERS';
export const API_GET_ONLINE_MEMBERS_SUCCESS = 'API_GET_ONLINE_MEMBERS_SUCCESS';
export const API_GET_ONLINE_MEMBERS_FAILED = 'API_GET_ONLINE_MEMBERS_FAILED';

export const RESET_USER_INFORMATION_STATE = 'RESET_USER_INFORMATION_STATE';

export const UPDATE_SELECTED_ORGANIZATION_STATE =
  'UPDATE_SELECTED_ORGANIZATION_STATE';
