import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from 'Layouts/DashboardLayout';
import { BorderBottom, PageHeading } from 'Components/CommonStyles';
import { MainContainer } from 'Pages/IntegrationsPage/Main/styles';
import { Header } from 'Pages/IntegrationsPage/Main/styles';
import axios from 'axios';
import { appConfig } from 'Config/app';
import { useState } from 'react';
import Loader from 'Components/Loader';
import { AccountPageContainer } from 'Containers/SettingsContainer/styles';
import {
  BottomSection,
  CardContainer,
  FileIconImg,
  FileName,
  FileNameContainer,
  ModifiedDate,
  TopSection,
  TopSectionRow,
} from 'Components/DocumentCard/styles';
import LogoMark from 'Components/LogoMark';
import { showWelcomeModal, updateTutorialState } from 'Redux/actions/tutorial';

const AIRHelpPage = () => {
  const auth = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const tutorialState = useSelector(state => state.tutorial);
  const dispatch = useDispatch();

  const authAPI = axios.create({
    baseURL: appConfig.API_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${auth.token}`,
    },
  });

  const handleBeginTutorial = () => {
    const tutorialPayload = {
      ...tutorialState,
      run: true,
      stepIndex: 0,
      showWelcomeModal: false,
    };
    dispatch(showWelcomeModal(false));
    dispatch(updateTutorialState(tutorialPayload));
  };
  useEffect(() => {
    setLoading(true);
    async function getData() {
      const response = await authAPI.get('/api/v1/organisation/air-helps/');
      setData(response?.data);
      setLoading(false);
    }
    getData();
  }, []);

  return (
    <DashboardLayout>
      <AccountPageContainer>
        <PageHeading>AIR Help</PageHeading>
        <BorderBottom />
        <MainContainer>
          <Header>Tutorials and videos</Header>
          {loading ? (
            <Loader />
          ) : (
            <div className="row" style={{ marginTop: '48px' }}>
              {data?.map((app, index) => (
                <div
                  className="col-lg-6 col-xl-4"
                  style={{ marginBottom: '12px' }}
                  key={index}
                >
                  <CardContainer>
                    <TopSection>
                      <TopSectionRow>
                        {app?.logo ? (
                          <FileIconImg src={app?.logo} alt="" />
                        ) : (
                          <LogoMark />
                        )}
                        <FileNameContainer>
                          <FileName>{app.title}</FileName>
                        </FileNameContainer>
                      </TopSectionRow>
                      <ModifiedDate>{app?.description}</ModifiedDate>
                    </TopSection>
                    <BottomSection>
                      {!app?.is_tutorial ? (
                        <a
                          className="btn btn-link text-decoration-none"
                          href={`${app?.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {'Open'}
                        </a>
                      ) : (
                        <div
                          className="btn btn-link text-decoration-none"
                          href="#"
                          onClick={handleBeginTutorial}
                        >
                          Launch Tutorial
                        </div>
                      )}
                    </BottomSection>
                  </CardContainer>
                </div>
              ))}
            </div>
          )}
        </MainContainer>
      </AccountPageContainer>
    </DashboardLayout>
  );
};

export default AIRHelpPage;
