export default function Comms(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5333 6.46666C13.9982 6.93103 14.367 7.48247 14.6186 8.08946C14.8703 8.69645 14.9998 9.34709 14.9998 10.0042C14.9998 10.6612 14.8703 11.3119 14.6186 11.9189C14.367 12.5259 13.9982 13.0773 13.5333 13.5417M6.46668 13.5333C6.0018 13.069 5.633 12.5175 5.38138 11.9105C5.12976 11.3035 5.00024 10.6529 5.00024 9.99583C5.00024 9.33875 5.12976 8.68812 5.38138 8.08113C5.633 7.47414 6.0018 6.9227 6.46668 6.45833M15.8917 4.10833C17.4539 5.67106 18.3316 7.7903 18.3316 10C18.3316 12.2097 17.4539 14.3289 15.8917 15.8917M4.10834 15.8917C2.54609 14.3289 1.66846 12.2097 1.66846 10C1.66846 7.7903 2.54609 5.67106 4.10834 4.10833M11.6667 10C11.6667 10.9205 10.9205 11.6667 10 11.6667C9.07954 11.6667 8.33334 10.9205 8.33334 10C8.33334 9.07952 9.07954 8.33333 10 8.33333C10.9205 8.33333 11.6667 9.07952 11.6667 10Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
