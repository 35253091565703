const VisaLogo = () => {
  return (
    <svg
      width="40"
      height="14"
      viewBox="0 0 40 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91708 13.4304H6.48418L3.90993 3.32063C3.78774 2.85558 3.52831 2.44444 3.14669 2.25067C2.19431 1.76372 1.14486 1.37618 0 1.18072V0.791494H5.53013C6.29337 0.791494 6.8658 1.37618 6.96121 2.05522L8.29688 9.34775L11.7281 0.791494H15.0656L9.91708 13.4304ZM16.9737 13.4304H13.7316L16.4013 0.791494H19.6434L16.9737 13.4304ZM23.8378 4.29286C23.9332 3.61213 24.5057 3.2229 25.1735 3.2229C26.223 3.12518 27.3661 3.32063 28.3202 3.8059L28.8926 1.08468C27.9386 0.695456 26.8891 0.5 25.9367 0.5C22.7901 0.5 20.5003 2.25068 20.5003 4.6804C20.5003 6.52881 22.1222 7.49934 23.2671 8.08403C24.5057 8.66703 24.9827 9.05625 24.8873 9.63925C24.8873 10.5137 23.9332 10.903 22.9809 10.903C21.836 10.903 20.6911 10.6115 19.6434 10.1245L19.0709 12.8474C20.2158 13.3327 21.4544 13.5281 22.5992 13.5281C26.1276 13.6242 28.3202 11.8752 28.3202 9.25002C28.3202 5.94412 23.8378 5.75035 23.8378 4.29286ZM39.6667 13.4304L37.0924 0.791494H34.3273C33.7549 0.791494 33.1825 1.18072 32.9917 1.76372L28.2248 13.4304H31.5623L32.2284 11.5837H36.3292L36.7108 13.4304H39.6667ZM34.8044 4.19514L35.7567 8.95854H33.0871L34.8044 4.19514Z"
        fill="#172B85"
      />
    </svg>
  );
};

export default VisaLogo;
