import React from 'react';
import { IconContainer, IconInnerContainer } from './styles';

export default function Icon({ active }) {
  return (
    <IconContainer>
      <IconInnerContainer active={active}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 1V4M15 1V4M9 20V23M15 20V23M20 9H23M20 14H23M1 9H4M1 14H4M6 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4ZM9 9H15V15H9V9Z"
            stroke={active ? '#ffffff' : '#7F56D9'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </IconInnerContainer>
    </IconContainer>
  );
}
