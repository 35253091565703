import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInformation } from '../../Redux/actions/login';
import {
  apiGetUserInformation,
  apiPatchUserInformation,
  resetUserInformationState,
  updateSelectedOrganization,
} from '../../Redux/actions/user_information';

import AccountPage from '../../Pages/AccountPage';
import { Route, Switch, useHistory } from 'react-router-dom';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { AccountPageContainer } from './styles';
import { PageHeading } from '../../Components/CommonStyles';
import BillingPage from '../../Pages/BillingPage';
import SettingsNavbar from './SettingsNavbar';
import TeamPage from 'Pages/TeamPage';
import AssetsPage from '../../Pages/AssetsPage';
import ChangePasswordPage from 'Pages/ChangePasswordPage';
import { apiTeamListRequest } from 'Redux/actions/team';
import { getObjectByLowestValue } from 'Utils/permissions';
import IntegrationsPage from 'Pages/IntegrationsPage';
import CustomSelect from 'Components/CustomSelect';

const SettingsContainer = () => {
  const auth = useSelector(state => state.auth);
  const userInformation = useSelector(state => state.userInformation);
  const team = useSelector(state => state.team);
  const dispatch = useDispatch();
  const history = useHistory();
  const teamCount = team?.teamMembers?.count || 0;
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const role = getObjectByLowestValue(
    userInformation?.data?.organisation_data,
    'role',
  )?.role;

  useEffect(() => {
    if (auth.token) {
      dispatch(apiGetUserInformation(auth.user_information?.id, auth.token));
      if (selectedOrg) {
        // console.log('selct org : ', selectedOrg);
        dispatch(
          apiTeamListRequest(
            auth.token,
            1,
            selectedOrg,
            '',
            null,
            undefined,
            null,
          ),
        );
      }
    }
  }, []);

  // useEffect(() => {
  //   if (userInformation.success) {
  //     dispatch(setUserInformation(userInformation.data));
  //     dispatch(resetUserInformationState());
  //   }
  //   if (userInformation) {
  //     const organisationData = [];
  //     userInformation?.data?.organisation_data.forEach(item => {
  //       if (item.role === 1 || item.role === 2)
  //         organisationData.push({
  //           value: item?.organisation?.id,
  //           label: item?.organisation?.name,
  //         });
  //     });
  //     setOrgList(organisationData);
  //     if (organisationData.length > 0) {
  //       setSelectedOrg(organisationData[0].value);
  //       handleChangeOrg(organisationData[0]);
  //     }
  //   }
  // }, [userInformation?.data?.organisation_data]);

  const organisationData = useMemo(() => {
    const data = userInformation?.data?.organisation_data || [];
    return data
      .filter(item => item.role === 1 || item.role === 2)
      .map(item => ({
        value: item?.organisation?.id,
        label: item?.organisation?.name,
      }));
  }, [userInformation?.data?.organisation_data]);

  useEffect(() => {
    if (userInformation.success && organisationData.length > 0) {
      dispatch(setUserInformation(userInformation.data));
      dispatch(resetUserInformationState());
      if (!selectedOrg) {
        setSelectedOrg(organisationData[0].value);
        handleChangeOrg(
          organisationData.find(
            data => data.value === userInformation?.selectedOrganisation,
          ) || organisationData[0],
        );
      }
    }
  }, [userInformation.success, organisationData, dispatch]);

  const onSubmit = data => {
    dispatch(
      apiPatchUserInformation(data, auth.user_information?.id, auth.token),
    );
  };

  const handleChangeOrg = org => {
    // console.log('selct org : ', org);
    setSelectedOrg(org.value);
    dispatch(updateSelectedOrganization(org.value));
    dispatch(apiTeamListRequest(auth.token, null, org.value));
  };

  return (
    <DashboardLayout>
      <AccountPageContainer>
        <div className="d-flex">
          <PageHeading>Account Details</PageHeading>
          {organisationData.length > 0 > 0 && (
            <div style={{ width: '200px', marginLeft: '16px' }}>
              <CustomSelect
                options={organisationData}
                ref={null}
                defaultValue={
                  organisationData.find(
                    data =>
                      data.value === userInformation?.selectedOrganisation,
                  ) || organisationData[0]
                }
                onChange={e => handleChangeOrg(e)}
              />
            </div>
          )}
        </div>
        <SettingsNavbar history={history} teamCount={teamCount} />
        <Switch>
          <Route path={'/'} exact>
            <AccountPage
              auth={auth}
              state={userInformation}
              onSubmit={onSubmit}
            />
          </Route>
          <Route path={'/settings/account-details'} exact>
            <AccountPage
              auth={auth}
              state={userInformation}
              onSubmit={onSubmit}
            />
          </Route>
          <Route path={'/settings/password'} exact>
            <ChangePasswordPage auth={auth} />
          </Route>
          {role === 1 && selectedOrg && (
            <>
              <Route path={'/settings/team'} exact>
                <TeamPage
                  auth={auth}
                  state={userInformation}
                  // onSubmit={onSubmit}
                  selectedOrg={selectedOrg}
                />
              </Route>
              <Route path={'/settings/integrations'} exact>
                <IntegrationsPage
                  auth={auth}
                  userInformation={userInformation}
                  selectedOrg={selectedOrg}
                  // onSubmit={onSubmit}
                />
              </Route>
              <Route path={'/settings/assets'} exact>
                <AssetsPage
                  organisationId={selectedOrg}
                  organisationName={
                    organisationData?.find(org => org?.value === selectedOrg)
                      ?.label
                  }
                />
              </Route>
              <Route path={'/settings/billing'} exact>
                <BillingPage organisationId={selectedOrg} />
              </Route>
            </>
          )}
        </Switch>
      </AccountPageContainer>
    </DashboardLayout>
  );
};

export default SettingsContainer;
