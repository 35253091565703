import * as React from 'react';

const Duplicate = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.16663 12.5H3.33329C2.89127 12.5 2.46734 12.3244 2.15478 12.0118C1.84222 11.6993 1.66663 11.2754 1.66663 10.8333V3.33333C1.66663 2.8913 1.84222 2.46738 2.15478 2.15482C2.46734 1.84226 2.89127 1.66666 3.33329 1.66666H10.8333C11.2753 1.66666 11.6992 1.84226 12.0118 2.15482C12.3244 2.46738 12.5 2.8913 12.5 3.33333V4.16666M9.16663 7.5H16.6666C17.5871 7.5 18.3333 8.24619 18.3333 9.16666V16.6667C18.3333 17.5871 17.5871 18.3333 16.6666 18.3333H9.16663C8.24615 18.3333 7.49996 17.5871 7.49996 16.6667V9.16666C7.49996 8.24619 8.24615 7.5 9.16663 7.5Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Duplicate;
