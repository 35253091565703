// Get Camera Status
export const API_GET_CAMERA_STATUS_REQUEST = 'API_GET_CAMERA_STATUS_REQUEST';
export const API_GET_CAMERA_STATUS_SUCCESS = 'API_GET_CAMERA_STATUS_SUCCESS';
export const API_GET_CAMERA_STATUS_FAILED = 'API_GET_CAMERA_STATUS_FAILED';
export const API_POST_USER_ONLINE_STATUS_REQUEST =
  'API_POST_USER_ONLINE_STATUS_REQUEST';
export const API_POST_USER_ONLINE_STATUS_FAILED =
  'API_POST_USER_ONLINE_STATUS_FAILED';
export const API_POST_USER_ONLINE_STATUS_SUCCESS =
  'API_POST_USER_ONLINE_STATUS_SUCCESS';
