import React from 'react';

export default function Instance() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F4EBFF" />
      <path
        d="M17.5002 10.8333V13.3333M22.5002 10.8333V13.3333M17.5002 26.6666V29.1666M22.5002 26.6666V29.1666M26.6668 17.4999H29.1668M26.6668 21.6666H29.1668M10.8335 17.4999H13.3335M10.8335 21.6666H13.3335M15.0002 13.3333H25.0002C25.9206 13.3333 26.6668 14.0794 26.6668 14.9999V24.9999C26.6668 25.9204 25.9206 26.6666 25.0002 26.6666H15.0002C14.0797 26.6666 13.3335 25.9204 13.3335 24.9999V14.9999C13.3335 14.0794 14.0797 13.3333 15.0002 13.3333ZM17.5002 17.4999H22.5002V22.4999H17.5002V17.4999Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
