import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { apiShootGetRequest, resetShoot } from '../../Redux/actions/shoot';
import { hideSideBarPage } from '../../Redux/actions/sidebar';

import DashboardLayout from '../../Layouts/DashboardLayout';
import Main from './Main';
import Loader from 'Components/Loader';

import tutorialLaunchpadBg from '../../Assets/images/tutorialLaunchpadBg.png';

const CameraControlPage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const shootState = useSelector(state => state.shoot);
  const { project_id } = useParams();

  // useEffect(() => {
  //   if (shootState.shoot?.background_image) {
  //     dispatch(hideSideBarPage());
  //   }
  // }, [shootState.shoot]);

  useEffect(() => {
    dispatch(resetShoot());
  }, []);

  useEffect(() => {
    dispatch(apiShootGetRequest(auth.token, project_id));
  }, [project_id]);

  return (
    <DashboardLayout>
      {
        project_id === '0'?
        (
          <img src={tutorialLaunchpadBg} className='img-fluid mt-4'/>
        ):
        (
          shootState.shoot ? (
            <Main state={shootState} project_id={project_id} />
          ) : (
            <Loader />
          )
        )
      }

      
    </DashboardLayout>
  );
};

export default CameraControlPage;
