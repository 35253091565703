// API Add Collections
export const API_ADD_COLLECTION_REQUEST = 'API_ADD_COLLECTION_REQUEST';
export const API_ADD_COLLECTION_SUCCESS = 'API_ADD_COLLECTION_SUCCESS';
export const API_ADD_COLLECTION_FAILED = 'API_ADD_COLLECTION_FAILED';

// API Add Tags
export const API_ADD_TAGS_REQUEST = 'API_ADD_TAGS_REQUEST';
export const API_ADD_TAGS_SUCCESS = 'API_ADD_TAGS_SUCCESS';
export const API_ADD_TAGS_FAILED = 'API_ADD_TAGS_FAILED';

// API Add Collections Items
export const API_ADD_COLLECTION_ITEMS_REQUEST =
  'API_ADD_COLLECTION_ITEMS_REQUEST';
export const API_ADD_COLLECTION_ITEMS_SUCCESS =
  'API_ADD_COLLECTION_ITEMS_SUCCESS';
export const API_ADD_COLLECTION_ITEMS_FAILED =
  'API_ADD_COLLECTION_ITEMS_FAILED';

// API All Collections
export const API_GET_ALL_COLLECTIONS_REQUEST =
  'API_GET_ALL_COLLECTIONS_REQUEST';
export const API_GET_ALL_COLLECTIONS_SUCCESS =
  'API_GET_ALL_COLLECTIONS_SUCCESS';
export const API_GET_ALL_COLLECTIONS_FAILED = 'API_GET_ALL_COLLECTIONS_FAILED';

// API All Tags
export const API_GET_ALL_TAGS_REQUEST = 'API_GET_ALL_TAGS_REQUEST';
export const API_GET_ALL_TAGS_SUCCESS = 'API_GET_ALL_TAGS_SUCCESS';
export const API_GET_ALL_TAGS_FAILED = 'API_GET_ALL_TAGS_FAILED';

// API All Media
export const API_GET_ALL_MEDIA_REQUEST = 'API_GET_ALL_MEDIA_REQUEST';
export const API_GET_ALL_MEDIA_SUCCESS = 'API_GET_ALL_MEDIA_SUCCESS';
export const API_GET_ALL_MEDIA_FAILED = 'API_GET_ALL_MEDIA_FAILED';

// API Delete Media
export const API_DELETE_MEDIA_REQUEST = 'API_DELETE_MEDIA_REQUEST';
export const API_DELETE_MEDIA_SUCCESS = 'API_DELETE_MEDIA_SUCCESS';
export const API_DELETE_MEDIA_FAILED = 'API_DELETE_MEDIA_FAILED';

// API Delete Collection
export const API_DELETE_COLLECTION_REQUEST = 'API_DELETE_COLLECTION_REQUEST';
export const API_DELETE_COLLECTION_SUCCESS = 'API_DELETE_COLLECTION_SUCCESS';
export const API_DELETE_COLLECTION_FAILED = 'API_DELETE_COLLECTION_FAILED';

// API Delete Collection Item
export const API_DELETE_COLLECTION_ITEMS_REQUEST =
  'API_DELETE_COLLECTION_ITEMS_REQUEST';
export const API_DELETE_COLLECTION_ITEMS_SUCCESS =
  'API_DELETE_COLLECTION_ITEMS_SUCCESS';
export const API_DELETE_COLLECTION_ITEMS_FAILED =
  'API_DELETE_COLLECTION_ITEMS_FAILED';

// API Get Collection By ID
export const API_GET_COLLECTION_BY_ID_REQUEST =
  'API_GET_COLLECTION_BY_ID_REQUEST';
export const API_GET_COLLECTION_BY_ID_SUCCESS =
  'API_GET_COLLECTION_BY_ID_SUCCESS';
export const API_GET_COLLECTION_BY_ID_FAILED =
  'API_GET_COLLECTION_BY_ID_FAILED';

// API Get Media By ID
export const API_GET_MEDIA_DETAILS_REQUEST = 'API_GET_MEDIA_DETAILS_REQUEST';
export const API_GET_MEDIA_DETAILS_SUCCESS = 'API_GET_MEDIA_DETAILS_SUCCESS';
export const API_GET_MEDIA_DETAILS_FAILED = 'API_GET_MEDIA_DETAILS_FAILED';

// API Get Clients
export const API_GET_CLIENTS_REQUEST = 'API_GET_CLIENTS_REQUEST';
export const API_GET_CLIENTS_SUCCESS = 'API_GET_CLIENTS_SUCCESS';
export const API_GET_CLIENTS_FAILED = 'API_GET_CLIENTS_FAILED';

// reset
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';

export const SET_MEDIA_CONTENT_DETAILS = 'SET_MEDIA_CONTENT_DETAILS';

export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
