// SIGNUP
export const API_SIGNUP_REQUEST = 'API_SIGNUP_REQUEST';
export const API_SIGNUP_REQUEST_SUCCESS = 'API_SIGNUP_REQUEST_SUCCESS';
export const API_SIGNUP_REQUEST_FAILED = 'API_SIGNUP_REQUEST_FAILED';

export const API_CHANGE_PASSWORD_REQUEST = 'API_CHANGE_PASSWORD_REQUEST';
export const API_CHANGE_PASSWORD_SUCCESS =
  'API_CHANGE_PASSWORD_REQUEST_SUCCESS';
export const API_CHANGE_PASSWORD_FAILED = 'API_CHANGE_PASSWORD_REQUEST_FAILED';

export const API_USER_CONFIRMATION_REQUEST = 'API_USER_CONFIRMATION_REQUEST';
export const API_USER_CONFIRMATION_SUCCESS = 'API_USER_CONFIRMATION_SUCCESS';
export const API_USER_CONFIRMATION_FAILED = 'API_USER_CONFIRMATION_FAILED';

export const SET_SUCCESS = 'SET_SUCCESS';
