import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import CustomSelect from 'Components/CustomSelect';
import {
  apiProjectPostRequest,
  resetProject,
  resetProjectState,
} from 'Redux/actions/project';
import { FormErrorText } from 'Components/CommonStyles';
import {
  FormActionRow,
  FormButtonGroup,
  SaveButton,
} from 'Components/AddProjectPage/styles';
import { OutlinedButton } from 'Components/IconButton/styles';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { namesRegEx } from 'Config/permissionConstant';

const AddNewProjectForm = ({ handleCancel, handleOk }) => {
  const auth = useSelector(state => state.auth);
  const userInformation = useSelector(state => state.userInformation);
  const project = useSelector(state => state.project);
  const [orgList, setOrgList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (new Date(watch('shoot_date')) > new Date(watch('shoot_end_date'))) {
      setValue('shoot_end_date', new Date(watch('shoot_date')));
    }
  }, [watch('shoot_date')]);

  useEffect(() => {
    if (project.postSuccess) {
      handleOk();
      dispatch(resetProject());
    }
  }, [project.postSuccess]);

  useEffect(() => {
    if (userInformation) {
      const organisationData = [];
      userInformation?.data?.organisation_data.forEach(item => {
        if (item.role === 1 || item.role === 2)
          organisationData.push({
            value: item?.organisation?.id,
            label: item?.organisation?.name,
          });
      });
      setOrgList(organisationData);
      if (organisationData.length > 0)
        setValue('organisation', [organisationData[0]]);
    }
  }, [userInformation]);

  useEffect(() => {
    if (orgList.length > 0) setValue('organisation', [orgList[0]]);
  }, [orgList]);

  const onSubmit = data => {
    if (data['shoot_time'] === null) {
      delete data['shoot_time'];
    } else {
      data['shoot_time'] = moment(data['shoot_time']).format('HH:mm');
    }
    data['shoot_end_date'] = new Date(
      data['shoot_end_date'],
    ).toLocaleDateString('sv');

    data['shoot_date'] = new Date(data['shoot_date']).toLocaleDateString('sv');

    data['organisation'] = data['organisation'].value;
    data['project_id'] =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    const submitData = {};
    Object.keys(data).forEach(k => {
      if (data[k]) {
        submitData[k] = data[k];
      }
    });
    dispatch(apiProjectPostRequest(submitData, auth.token, true));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: '24px' }}>
      <div className="mb-3">
        <label className="form-label">Project Title</label>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: 'This field is required.',
            pattern: {
              value: namesRegEx.value,
              message: 'Project Title ' + namesRegEx.message,
            },
          }}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="form-control"
              placeholder="Project Title"
            />
          )}
        />
        {errors.name && (
          <FormErrorText className="form-text">
            {errors.name.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Organisation</label>
        <div>
          <Controller
            name="organisation"
            control={control}
            rules={{
              required: 'This field is required.',
            }}
            render={({ field }) => (
              <CustomSelect
                options={orgList}
                {...field}
                defaultValue={orgList.length > 0 ? [orgList[0]] : []}
                ref={null}
              />
            )}
          />
        </div>
        {errors.organisation && (
          <FormErrorText className="form-text">
            {errors.organisation.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Client</label>
        <Controller
          name="client"
          control={control}
          defaultValue=""
          rules={{
            required: false,
            pattern: {
              value: namesRegEx.value,
              message: 'Client Name ' + namesRegEx.message,
            },
          }}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="form-control"
              placeholder="Client"
            />
          )}
        />
        {errors.client && (
          <FormErrorText className="form-text">
            {errors.client.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Shoot Date</label>
        <Controller
          name="shoot_date"
          control={control}
          defaultValue={new Date()}
          rules={{
            validate: value => {
              if (value && new Date(value) > watch('shoot_end_date'))
                return "Shoot date can't be greater than shoot end date";
              else clearErrors('shoot_end_date');
            },
          }}
          render={({ field }) => (
            <DatePicker
              {...field}
              type="date"
              className="form-control"
              calendarClassName={'custom-calendar'}
              placeholder="Shoot Date"
              dayPlaceholder="dd"
              clearIcon={null}
              minDate={new Date()}
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
            />
          )}
        />
        {errors.shoot_date && (
          <FormErrorText className="form-text">
            {errors.shoot_date.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Shoot Time</label>
        <Controller
          name="shoot_time"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            // <input
            //   {...field}
            //   step="60"
            //   type="time"
            //   className="form-control"
            //   placeholder="Shoot Time"
            // />
            <TimePicker
              {...field}
              className="form-control"
              placeholder="Shoot Time"
              showSecond={false}
              use12Hours={false}
              placement="bottomLeft"
              autoComplete="off"
            />
          )}
        />
        {errors.shoot_time && (
          <FormErrorText className="form-text">
            {errors.shoot_time.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">End Date</label>
        <Controller
          name="shoot_end_date"
          control={control}
          defaultValue={new Date()}
          rules={{
            validate: value => {
              if (value && new Date(value) < watch('shoot_date'))
                return "Shoot end date can't be smaller than shoot date";
              else clearErrors('shoot_date');
            },
          }}
          render={({ field }) => (
            <DatePicker
              {...field}
              type="date"
              clearIcon={null}
              className="form-control"
              calendarClassName={'custom-calendar'}
              placeholder="End Date"
              minDate={new Date(watch('shoot_date'))}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
            />
          )}
        />
        {errors.shoot_end_date && (
          <FormErrorText className="form-text">
            {errors.shoot_end_date.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Location</label>
        <Controller
          name="location"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="form-control"
              placeholder="Location"
            />
          )}
        />
        {errors.location && (
          <FormErrorText className="form-text">
            {errors.location.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Description</label>
        <Controller
          name="description"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <textarea
              {...field}
              className="form-control"
              placeholder="Enter project details"
              rows="3"
            ></textarea>
          )}
        />
        {errors.description && (
          <FormErrorText className="form-text">
            {errors.description.message}
          </FormErrorText>
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">Project Status</label>

        <Controller
          name="status"
          control={control}
          defaultValue="1"
          rules={{
            required: 'This field is required.',
          }}
          render={({ field }) => (
            <select {...field} className="form-select">
              <option value="1">Active</option>
              <option value="0">Archived</option>
            </select>
          )}
        />
        {errors.status && (
          <FormErrorText className="form-text">
            {errors.status.message}
          </FormErrorText>
        )}
      </div>
      <FormActionRow>
        <FormButtonGroup>
          <OutlinedButton onClick={handleCancel} className="btn">
            Cancel
          </OutlinedButton>
          <SaveButton
            disabled={project.isLoading}
            type="submit"
            className="btn btn-primary"
          >
            Save
          </SaveButton>
        </FormButtonGroup>
      </FormActionRow>
    </form>
  );
};

export default AddNewProjectForm;
