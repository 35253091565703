import React, { useState } from 'react';
import {
  AssignedProjectDiv,
  Btn2,
  DeleteInstanceDiv,
  EditCamTimeImg,
  OptionsValueContainer,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RightNavLine,
  RightNavSecDiv,
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSDiv5,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SDMHeadingPWhite,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
} from './styles';
import cross from '../../Assets/images/cross.png';
import binWhite from '../../Assets/images/binWhite.png';
import errorIcon from '../../Assets/icons/errorIcon.svg';
import tick from '../../Assets/images/tick.png';
import Select, { components } from 'react-select';
import {
  DropDownIconContainer,
  ModalFooter,
  OptionText,
  SelectedValueContainer,
} from '../../Components/CommonStyles';
import {
  regionOptions,
  sizeOptions,
  typeOptions,
} from 'Config/permissionConstant';
import { Button } from 'react-bootstrap';
import Modal from 'Components/Modal';
import Badge from 'Components/Badge';
import { COLOR_CODE } from 'Config/colors';

const EditInstance = ({
  editInstance,
  setSideNavPage,
  onGearEditApi,
  closeSideBar,
  isInstanceInfo = false,
  ...props
}) => {
  const [instance, setInstance] = useState(editInstance);
  const [optionSelect, setoptionSelect] = useState(editInstance?.role);
  const [showInstanceModal, setShowInstanceModal] = useState(false);
  const [error, setError] = useState({
    nick_name: null,
    owner_name: null,
    instance_size: null,
    instance_type: null,
    lan_ip: null,
    public_ip: null,
  });

  const checkIPValidity = (value, fieldName) => {
    const ipRegEx =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
    if (value.length > 0 && !ipRegEx.test(value)) {
      setError(prev => ({
        ...prev,
        [fieldName]: 'Invalid IP address',
      }));
      setInstance(prev => ({ ...prev, [fieldName]: value }));
    } else {
      setInstance(prev => ({ ...prev, [fieldName]: value }));
      setError(prev => ({ ...prev, [fieldName]: null }));
    }
  };
  let instanceModalData = {
    title: 'Delete ' + instance?.nick_name,
    message: `Are you sure you want to delete "${instance?.nick_name}"`,
  };
  const instanceModalFooter = (
    <ModalFooter>
      <Button onClick={() => setShowInstanceModal(false)} variant="primary">
        No
      </Button>
      <Button
        onClick={() => {
          onGearEditApi(instance, true);
          closeSideBar();
        }}
        variant="danger"
      >
        Delete
      </Button>
    </ModalFooter>
  );

  const customRoleStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '100%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };

  const ValueContainer = props => {
    return (
      <components.Option {...props}>
        <OptionsValueContainer>
          <OptionText>{props.data.label}</OptionText>
          {props.data.label === optionSelect || props.isSelected ? (
            <img src={tick} style={{ width: 13.33, height: 9.17 }} />
          ) : null}
        </OptionsValueContainer>
      </components.Option>
    );
  };

  const DropDownIcon = props => (
    <DropDownIconContainer>
      <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1 1.5 5 5 5-5"
          stroke="#667085"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </DropDownIconContainer>
  );
  // console.log('editInstance', editInstance);

  const onConfirm = () => {
    if (!instance.instance_type) {
      setError(prev => ({ ...prev, instance_type: 'This field is required' }));
      return;
    }

    if (!instance.instance_size) {
      setError(prev => ({ ...prev, instance_size: 'This field is required' }));
      return;
    }

    if (!instance.owner_name) {
      setError(prev => ({ ...prev, owner_name: 'This field is required' }));
      return;
    }

    if (!instance.nick_name) {
      setError(prev => ({ ...prev, nick_name: 'This field is required' }));
      return;
    }

    if (error?.public_ip || error?.lan_ip) return;
    onGearEditApi(instance);
    closeSideBar();
  };
  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>
          {isInstanceInfo ? 'Instance Info' : 'Edit Instance'}
        </SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Type</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                defaultValue={{
                  label: instance?.instance_type
                    ? typeOptions.filter(
                        f => f.value === instance?.instance_type,
                      )[0]?.label
                    : '',
                  value: instance?.instance_type ? instance?.instance_type : '',
                }}
                // onChange={handleRoleFilter}
                options={typeOptions}
                placeholder={'Instance Type'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_type: e.value,
                  }))
                }
                // onChange={e => setoptionSelect(e.label)}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
            </SBMSDiv3a>
            {error?.instance_type && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.instance_type}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Software License Number</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter License"
                defaultValue={instance?.vmix_licence_number}
                style={{ color: '#667085' }}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    vmix_licence_number: e.target.value,
                  }))
                }
              />
            </SBMSDiv3>
          </div>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Region</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                defaultValue={{
                  label: instance?.instance_regioin
                    ? regionOptions.filter(
                        f => f.value === instance?.instance_regioin,
                      )[0]?.label
                    : '',
                  value: instance?.instance_regioin
                    ? instance?.instance_regioin
                    : '',
                }}
                // onChange={handleRoleFilter}
                // onChange={e => setoptionSelect(e.label)}
                options={regionOptions}
                placeholder={'Select Region'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_regioin: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
            </SBMSDiv3a>
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Size</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                defaultValue={{
                  label: instance?.instance_size
                    ? sizeOptions.filter(
                        f => f.value === instance?.instance_size,
                      )[0]?.label
                    : '',
                  value: instance?.instance_size ? instance?.instance_size : '',
                }}
                // onChange={handleRoleFilter}
                options={sizeOptions}
                placeholder={'Size'}
                styles={customRoleStyles}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_size: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
              />
            </SBMSDiv3a>
            {error?.instance_size && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.instance_size}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Owner"
                maxLength={30}
                defaultValue={instance?.owner_name}
                style={{ color: '#667085' }}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    owner_name: e.target.value,
                  }))
                }
              />
            </SBMSDiv3>
            {error?.owner_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.owner_name}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Nickname"
                defaultValue={instance?.nick_name}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    nick_name: e.target.value,
                  }))
                }
              />
            </SBMSDiv3>
            {error?.nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.nick_name}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Current Project Assigned</SDMHeadingP>
            <AssignedProjectDiv>
              {/* <SBMSInput2
                type="text"
                placeholder="Enter Project"
                // maxLength={30}
                readOnly
                // defaultValue={editInstance?.user_info.email}
              /> */}
              {instance?.assigned_project?.length > 0 ? (
                instance?.assigned_project.map(item => (
                  <Badge
                    color={COLOR_CODE[Number(item.id % 11)].color}
                    bgColor={COLOR_CODE[Number(item.id % 11)].bgColor}
                    title={item?.project?.name}
                  />
                ))
              ) : (
                <p>There is no project assigned.</p>
              )}
            </AssignedProjectDiv>
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="ID 0000000-B"
                maxLength={30}
                readOnly
                defaultValue={instance?.air_id}
              />
            </SBMSDiv3>
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                // maxLength={30}
                // readOnly
                defaultValue={instance?.public_ip}
                onChange={e =>
                  // setInstance(prev => ({
                  //   ...prev,
                  //   public_ip: e.target.value,
                  // }))
                  checkIPValidity(e.target.value, 'public_ip')
                }
              />
            </SBMSDiv3>
            {error?.public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.public_ip}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>LAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                // maxLength={30}
                // readOnly
                defaultValue={instance?.lan_ip}
                onChange={e =>
                  // setInstance(prev => ({
                  //   ...prev,
                  //   lan_ip: e.target.value,
                  // }))
                  checkIPValidity(e.target.value, 'lan_ip')
                }
              />
            </SBMSDiv3>
            {error?.lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.lan_ip}
                </p>
              </div>
            )}
          </div>
          {/* -------------------- For Edit Component ----------------- */}
          <RightNavLine />
          {/* <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Expiration Date</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="12/01/2022"
                maxLength={30}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    public_ip: e.target.value,
                  }))
                }
                // defaultValue={editInstance?.user_info.email}
              />
            </SBMSDiv3>
          </div> */}
          {!isInstanceInfo && (
            <DeleteInstanceDiv onClick={() => setShowInstanceModal(true)}>
              <EditCamTimeImg src={binWhite} />
              <SDMHeadingPWhite>Delete Instance</SDMHeadingPWhite>
            </DeleteInstanceDiv>
          )}
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa
            onClick={() => {
              // onGearEditApi(instance);
              onConfirm();
            }}
          >
            Confirm
          </TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>

      <Modal
        show={showInstanceModal}
        onHide={() => setShowInstanceModal(false)}
        icon={errorIcon}
        title={instanceModalData?.title}
        body={instanceModalData?.message}
        footer={instanceModalFooter}
      />
    </RightNavContainer>
  );
};

export default EditInstance;
