const GlobeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3490_125247)">
        <path
          d="M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332M18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665M18.3332 9.99984H1.6665M9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984M9.99984 18.3332C12.0842 16.0512 13.2688 13.0898 13.3332 9.99984C13.2688 6.90987 12.0842 3.94846 9.99984 1.6665M9.99984 18.3332C7.91544 16.0512 6.73088 13.0898 6.6665 9.99984C6.73088 6.90987 7.91544 3.94846 9.99984 1.6665M1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3490_125247">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GlobeIcon;
