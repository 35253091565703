export const API_ADD_PAYMENT_METHOD_REQUEST = 'API_ADD_PAYMENT_METHOD_REQUEST';
export const API_ADD_PAYMENT_METHOD_SUCCESS = 'API_ADD_PAYMENT_METHOD_SUCCESS';
export const API_ADD_PAYMENT_METHOD_FAILED = 'API_ADD_PAYMENT_METHOD_FAILED';

export const API_GET_ALL_PAYMENT_METHOD_REQUEST =
  'API_GET_ALL_PAYMENT_METHOD_REQUEST';
export const API_GET_ALL_PAYMENT_METHOD_SUCCESS =
  'API_GET_ALL_PAYMENT_METHOD_SUCCESS';
export const API_GET_ALL_PAYMENT_METHOD_FAILED =
  'API_GET_ALL_PAYMENT_METHOD_FAILED';

export const API_GET_ALL_INVOICES_REQUEST = 'API_GET_ALL_INVOICES_REQUEST';
export const API_GET_ALL_INVOICES_SUCCESS = 'API_GET_ALL_INVOICES_SUCCESS';
export const API_GET_ALL_INVOICES_FAILED = 'API_GET_ALL_INVOICES_FAILED';

export const API_CHANGE_DEFAULT_PAYMENT_METHOD_REQUEST =
  'API_CHANGE_DEFAULT_PAYMENT_METHOD_REQUEST';
export const API_CHANGE_DEFAULT_PAYMENT_METHOD_SUCCESS =
  'API_CHANGE_DEFAULT_PAYMENT_METHOD_SUCCESS';
export const API_CHANGE_DEFAULT_PAYMENT_METHOD_FAILED =
  'API_CHANGE_DEFAULT_PAYMENT_METHOD_FAILED';

export const API_PATCH_PAYMENT_METHOD_REQUEST =
  'API_PATCH_PAYMENT_METHOD_REQUEST';
export const API_PATCH_PAYMENT_METHOD_SUCCESS =
  'API_PATCH_PAYMENT_METHOD_SUCCESS';
export const API_PATCH_PAYMENT_METHOD_FAILED =
  'API_PATCH_PAYMENT_METHOD_FAILED';

export const RESET_PAYMENT = 'RESET_PAYMENT';
