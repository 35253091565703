export const API_PROJECT_GET_REQUEST = 'API_PROJECT_GET_REQUEST';
export const API_PROJECT_GET_SUCCESS = 'API_PROJECT_GET_SUCCESS';
export const API_PROJECT_GET_FAILED = 'API_PROJECT_GET_FAILED';

export const API_ARCHIVE_PROJECT_GET_REQUEST =
  'API_ARCHIVE_PROJECT_GET_REQUEST';
export const API_ARCHIVE_PROJECT_GET_SUCCESS =
  'API_ARCHIVE_PROJECT_GET_SUCCESS';
export const API_ARCHIVE_PROJECT_GET_FAILED = 'API_ARCHIVE_PROJECT_GET_FAILED';

// Add project
export const API_PROJECT_POST_REQUEST = 'API_PROJECT_POST_REQUEST';
export const API_DUPLICATE_PROJECT_POST_REQUEST =
  'API_DUPLICATE_PROJECT_POST_REQUEST';
export const API_PROJECT_POST_SUCCESS = 'API_PROJECT_POST_SUCCESS';
export const API_PROJECT_POST_FAILED = 'API_PROJECT_POST_FAILED';

// Patch project
export const API_PROJECT_PATCH_REQUEST = 'API_PROJECT_PATCH_REQUEST';
export const API_PROJECT_PATCH_SUCCESS = 'API_PROJECT_PATCH_SUCCESS';
export const API_PROJECT_PATCH_FAILED = 'API_PROJECT_PATCH_FAILED';

// Delete project
export const API_PROJECT_DELETE_REQUEST = 'API_PROJECT_DELETE_REQUEST';
export const API_PROJECT_DELETE_SUCCESS = 'API_PROJECT_DELETE_SUCCESS';
export const API_PROJECT_DELETE_FAILED = 'API_PROJECT_DELETE_FAILED';

// API Add project member
export const API_ADD_MEMBER_REQUEST = 'API_ADD_MEMBER_REQUEST';
export const API_ADD_MEMBER_SUCCESS = 'API_ADD_MEMBER_SUCCESS';
export const API_ADD_MEMBER_FAILED = 'API_ADD_MEMBER_FAILED';

// API Remove project member
export const API_REMOVE_MEMBER_REQUEST = 'API_REMOVE_MEMBER_REQUEST';
export const API_REMOVE_MEMBER_SUCCESS = 'API_REMOVE_MEMBER_SUCCESS';
export const API_REMOVE_MEMBER_FAILED = 'API_REMOVE_MEMBER_FAILED';

// API Project Links
export const API_ADD_PROJECT_LINKS_REQUEST = 'API_ADD_PROJECT_LINKS_REQUEST';
export const API_ADD_PROJECT_LINKS_SUCCESS = 'API_ADD_PROJECT_LINKS_SUCCESS';
export const API_ADD_PROJECT_LINKS_FAILED = 'API_ADD_PROJECT_LINKS_FAILED';

// API PATCH Project Links
export const API_EDIT_PROJECT_LINKS_REQUEST = 'API_EDIT_PROJECT_LINKS_REQUEST';
export const API_EDIT_PROJECT_LINKS_SUCCESS = 'API_EDIT_PROJECT_LINKS_SUCCESS';
export const API_EDIT_PROJECT_LINKS_FAILED = 'API_EDIT_PROJECT_LINKS_FAILED';

// API Get Project Links
export const API_GET_PROJECT_LINKS_REQUEST = 'API_GET_PROJECT_LINKS_REQUEST';
export const API_GET_PROJECT_LINKS_SUCCESS = 'API_GET_PROJECT_LINKS_SUCCESS';
export const API_GET_PROJECT_LINKS_FAILED = 'API_GET_PROJECT_LINKS_FAILED';

// API Project Files
export const API_ADD_PROJECT_FILES_REQUEST = 'API_ADD_PROJECT_FILES_REQUEST';
export const API_ADD_PROJECT_FILES_SUCCESS = 'API_ADD_PROJECT_FILES_SUCCESS';
export const API_ADD_PROJECT_FILES_FAILED = 'API_ADD_PROJECT_FILES_FAILED';

export const API_EDIT_PROJECT_FILES_REQUEST = 'API_EDIT_PROJECT_FILES_REQUEST';
export const API_EDIT_PROJECT_FILES_SUCCESS = 'API_EDIT_PROJECT_FILES_SUCCESS';
export const API_EDIT_PROJECT_FILES_FAILED = 'API_EDIT_PROJECT_FILES_FAILED';

export const API_GET_PROJECT_FILES_REQUEST = 'API_GET_PROJECT_FILES_REQUEST';
export const API_GET_PROJECT_FILES_SUCCESS = 'API_GET_PROJECT_FILES_SUCCESS';
export const API_GET_PROJECT_FILES_FAILED = 'API_GET_PROJECT_FILES_FAILED';

export const API_DELETE_PROJECT_FILES_REQUEST =
  'API_DELETE_PROJECT_FILES_REQUEST';
export const API_DELETE_PROJECT_FILES_SUCCESS =
  'API_DELETE_PROJECT_FILES_SUCCESS';
export const API_DELETE_PROJECT_FILES_FAILED =
  'API_DELETE_PROJECT_FILES_FAILED';

// Delete Project Links
export const API_DELETE_PROJECT_LINKS_REQUEST =
  'API_DELETE_PROJECT_LINKS_REQUEST';
export const API_DELETE_PROJECT_LINKS_SUCCESS =
  'API_DELETE_PROJECT_LINKS_SUCCESS';
export const API_DELETE_PROJECT_LINKS_FAILED =
  'API_DELETE_PROJECT_LINKS_FAILED';

// reset
export const RESET_PROJECT = 'RESET_PROJECT';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INITIAL_STATE';
