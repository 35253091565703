import React, { useState, useEffect } from 'react';
import {
  Btn2,
  OptionsValueContainer,
  RightNavContainer,
  RightNavContainerFoot,
  RightNavContainerX,
  RightNavContainerXPar,
  RightNavSecDiv,
  RNCFBtn,
  RNCFBtn2,
  SBMSDiv3,
  SBMSDiv3a,
  SBMSImg,
  SBMSInput2,
  SDMHeading,
  SDMHeadingP,
  SideBarMainDiv,
  TeamHeadBtnImgP,
  TeamHeadBtnImgPa,
} from '../styles';
import cross from '../../../Assets/images/cross.png';
import edit from '../../../Assets/images/editWhite.png';
import tick from '../../../Assets/images/tick.png';
import Select, { components } from 'react-select';
import {
  DropDownIconContainer,
  OptionText,
  SelectedValueContainer,
} from '../../../Components/CommonStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  apiGearPostRequest,
  apiGetInstanceDetailRequest,
  clearInstanceDetails,
} from 'Redux/actions/gear';
import { apiGetAllAssetsRequest } from 'Redux/actions/assets';
import {
  regionOptions,
  sizeOptions,
  typeOptions,
} from 'Config/permissionConstant';

const OnboardInstance = ({
  onChangeRightNav,
  organisationId,
  onGearEditApi,
  organisationName
}) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [instance, setInstance] = useState({
    owner_name: organisationName
  });
  const gears = useSelector(state => state.gear);
  const [errorTxt, setErrorTxt] = useState('');
  const [error, setError] = useState({
    nick_name: false,
    air_id: false,
    instance_size: false,
    instance_type: false,
    lan_ip: null,
    public_ip: null,
  });

  useEffect(() => {
    if (instance.air_id?.length > 4) {
      dispatch(apiGetInstanceDetailRequest(instance?.air_id, auth.token));
    }
  }, [instance.air_id]);

  // useEffect(() => {
  //   if (instance.air_id?.length > 4) {
  //     dispatch(apiGetInstanceDetailRequest(instance?.air_id, auth.token));
  //   }
  // }, [instance.air_id]);


  useEffect(() => {
    if (gears?.instanceDetails) {
      console.log('INSTANCE', gears?.instanceDetails);
      setInstance(prev => ({
        ...prev,
        id: gears?.instanceDetails?.id,
        owner_name: gears?.instanceDetails?.owner_name,
        nick_name: gears?.instanceDetails?.nick_name,
        lan_ip: gears?.instanceDetails?.lan_ip,
        public_ip: gears?.instanceDetails?.public_ip,
        auto_update: gears?.instanceDetails?.auto_update,
        instance_size: gears?.instanceDetails?.instance_size,
        instance_type: gears?.instanceDetails?.instance_type,
        instance_regioin: gears?.instanceDetails?.instance_regioin,
        vmix_licence_number: gears?.instanceDetails?.vmix_licence_number,
      }));
      setError(prev => ({
        ...prev,
        air_id: null,
      }));
      setErrorTxt('');
    } else if (gears?.instanceDetailsErr) {
      setError(prev => ({
        ...prev,
        air_id: gears?.instanceDetailsErr,
      }));
      setErrorTxt(gears?.instanceDetailsErr);
    }

    return () => dispatch(clearInstanceDetails());
  }, [gears?.instanceDetails, gears?.instanceDetailsErr, dispatch]);

  const checkIPValidity = (value, fieldName) => {
    const ipRegEx =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?$/;
    if (value.length > 0 && !ipRegEx?.test(value)) {
      setError(prev => ({
        ...prev,
        [fieldName]: 'Invalid IP address',
      }));
      setInstance(prev => ({ ...prev, [fieldName]: value }));
    } else {
      setInstance(prev => ({ ...prev, [fieldName]: value }));
      setError(prev => ({ ...prev, [fieldName]: null }));
    }
  };
  console.log('err : ', error);

  // let organisation_id =
  //   auth.user_information.organisation_data[0].organisation.id;

  const closeSideBar = () => {
    dispatch(clearInstanceDetails());
    onChangeRightNav('');
  };

  const onConfirm = () => {
    if (!instance.instance_type) {
      setError(prev => ({ ...prev, instance_type: true }));
      setErrorTxt('This field is required');
      return;
    }

    if (!instance.instance_size) {
      setError(prev => ({ ...prev, instance_size: true }));
      setErrorTxt('This field is required');
      return;
    }

    if (!instance.owner_name) {
      setError(prev => ({ ...prev, owner_name: true }));
      setErrorTxt('This field is required');
      return;
    }

    if (!instance.nick_name) {
      setError(prev => ({ ...prev, nick_name: true }));
      setErrorTxt('This field is required');
      return;
    }

    if (!instance.air_id) {
      setError(prev => ({ ...prev, air_id: true }));
      setErrorTxt('This field is required');
      return;
    }
    if (error?.public_ip || error?.lan_ip) return;
    closeSideBar();
    instance['organisation'] = organisationId;

    // console.log('instance', instance);
    // console.log('organisation', JSON.stringify(instance));
    //dispatch(apiGearPostRequest(instance, auth.token, '2', organisationId));
    // dispatch(apiGetAllAssetsRequest(auth.token, organisationId));

    onGearEditApi(instance);
  };
  useEffect(() => {
    setError(prev => ({
      ...prev,
      nick_name: false,
      air_id: false,
      instance_size: false,
      instance_type: false,
      owner_name: false,
    }));
    setErrorTxt('');
  }, [
    instance?.nick_name,
    instance?.air_id,
    instance?.instance_size,
    instance?.instance_type,
    instance?.owner_name,
  ]);

  const customRoleStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#344054',
      width: '90%',
      backgroundColor: '#fff',
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
      border: '1px solid #D0D5DD',
      borderRadius: 8,
      height: 44,
      fontWeight: 400,
      color: '#344054',
      fontSize: 16,
      fontFamily: 'Inter',
    }),
    container: base => ({
      ...base,
      flex: 1,
    }),
  };
  // const [optionSelect, setoptionSelect] = useState(editUser?.role);
  const [manageProjects, setManageProjects] = useState(false);
  const ValueContainer = props => {
    return (
      <components.Option {...props}>
        <OptionsValueContainer>
          <OptionText>{props.data.label}</OptionText>
          {props.data.label === props.isSelected ? (
            <img src={tick} style={{ width: 13.33, height: 9.17 }} />
          ) : null}
        </OptionsValueContainer>
      </components.Option>
    );
  };

  const DropDownIcon = props => (
    <DropDownIconContainer>
      <svg width={12} height={8} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m1 1.5 5 5 5-5"
          stroke="#667085"
          strokeWidth={1.667}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </DropDownIconContainer>
  );

  const nameChangeHandler = e => {
    const reg = /^([^$%,./<>?;':"`~\_=+(\*&^%$#@!|)]*)$/;
    if (reg.test(e.target.value)) {
      setInstance(prev => ({
        ...prev,
        vmix_licence_number: e.target.value,
      }));
    }
  };


  return (
    <RightNavContainer>
      {/* =========================Header=================================== */}
      <RightNavContainerX onClick={closeSideBar}>
        <RightNavContainerXPar src={cross} />
      </RightNavContainerX>
      <SideBarMainDiv>
        <SDMHeading>Onboard Instance</SDMHeading>
        {/* =========================Body=================================== */}
        <RightNavSecDiv>
          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Type</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                // defaultValue={typeOptions[0]}
                // onChange={handleRoleFilter}
                options={typeOptions}
                // placeholder={editUser?.role ? editUser?.role : 'Instance Type'}
                placeholder={'Instance Type'}
                styles={customRoleStyles}
                // onChange={e => setoptionSelect(e.label)}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_type: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
                value={
                  instance?.instance_type
                    ? typeOptions?.find(
                        type => type?.value === instance?.instance_type,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
            {error.instance_type && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Software License Number</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter License"
                // maxLength={30}
                defaultValue={''}
                value={instance?.vmix_licence_number}
                style={{ color: '#667085' }}
                onChange={nameChangeHandler}
              />
            </SBMSDiv3>
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Instance Region</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                // defaultValue={regionOptions[0]}
                // onChange={handleRoleFilter}
                options={regionOptions}
                placeholder={'Select Region'}
                styles={customRoleStyles}
                // onChange={e => setoptionSelect(e.label)}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_regioin: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
                value={
                  instance?.instance_regioin
                    ? regionOptions?.find(
                        region => region?.value === instance?.instance_regioin,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
          </div>

          <div style={{ marginTop: 15, width: '100%' }}>
            <SDMHeadingP>Size:</SDMHeadingP>
            <SBMSDiv3a>
              <Select
                // defaultValue={sizeOptions[0]}
                // onChange={handleRoleFilter}
                options={sizeOptions}
                placeholder={'Instance Size'}
                styles={customRoleStyles}
                // onChange={e => setoptionSelect(e.label)}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    instance_size: e.value,
                  }))
                }
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: DropDownIcon,
                  Option: ValueContainer,
                  // Option: () => (optionSelect ? ValueContainer : null),
                }}
                value={
                  instance?.instance_size
                    ? sizeOptions?.find(
                        size => size?.value === instance?.instance_size,
                      ) || ''
                    : ''
                }
              />
            </SBMSDiv3a>
            {error.instance_size && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>

          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Owner</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Owner"
                // maxLength={30}
                // defaultValue={editUser?.user_info.first_name}
                style={{ color: '#667085' }}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    owner_name: e.target.value,
                  }))
                }
                value={instance?.owner_name || ''}
              />
            </SBMSDiv3>
            {error.owner_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Nickname</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Nickname"
                // maxLength={30}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    nick_name: e.target.value,
                  }))
                }
                value={instance?.nick_name || ''}
                // defaultValue={editUser?.user_info.last_name}
              />
            </SBMSDiv3>
            {error.nick_name && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>

          {/* <div style={{ marginTop: 15 }}>
            <SDMHeadingP>Current Project Assigned</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="Enter Project"
                // maxLength={30}
                // defaultValue={editUser?.user_info.email}
                // onChange={e =>
                //   setInstance(prev => ({
                //     ...prev,
                //     owner_name: e.target.value,
                //   }))
                // }
              />
            </SBMSDiv3>
          </div> */}
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>AIR ID</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="ID 0192847502-B"
                // maxLength={30}
                // defaultValue={editUser?.user_info.email}
                onChange={e =>
                  setInstance(prev => ({
                    ...prev,
                    air_id: e.target.value,
                  }))
                }
                value={instance?.air_id || ''}
              />
            </SBMSDiv3>
            {error.air_id && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {errorTxt}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>WAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                // maxLength={30}
                // readOnly
                // defaultValue={editUser?.user_info.email}
                onChange={e => {
                  // setInstance(prev => ({
                  //   ...prev,
                  //   public_ip: e.target.value,
                  // }));
                  checkIPValidity(e.target.value, 'public_ip');
                }}
                value={instance?.public_ip || ''}
              />
            </SBMSDiv3>
            {error.public_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.public_ip}
                </p>
              </div>
            )}
          </div>
          <div style={{ marginTop: 15 }}>
            <SDMHeadingP>LAN IP</SDMHeadingP>
            <SBMSDiv3>
              <SBMSInput2
                type="text"
                placeholder="-"
                // readOnly
                // maxLength={30}
                onChange={
                  e => {
                    checkIPValidity(e.target.value, 'lan_ip');
                  }
                  // setInstance(prev => ({
                  //   ...prev,
                  //   lan_ip: e.target.value,
                  // }))
                }
                value={instance?.lan_ip || ''}
                // defaultValue={editUser?.user_info.email}
              />
            </SBMSDiv3>
            {error.lan_ip && (
              <div>
                <p style={{ color: 'red', marginTop: 3, fontSize: 14 }}>
                  {error.lan_ip}
                </p>
              </div>
            )}
          </div>
        </RightNavSecDiv>
      </SideBarMainDiv>
      {/* =========================Footer=================================== */}
      <RightNavContainerFoot>
        <RNCFBtn>
          <TeamHeadBtnImgP onClick={closeSideBar}>Cancel</TeamHeadBtnImgP>
        </RNCFBtn>
        <RNCFBtn2>
          <TeamHeadBtnImgPa onClick={onConfirm}>Confirm</TeamHeadBtnImgPa>
        </RNCFBtn2>
      </RightNavContainerFoot>
    </RightNavContainer>
  );
};

export default OnboardInstance;
